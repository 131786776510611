import React, { Suspense, useEffect, useState } from "react";
import { Container, Col, Button, Row } from "reactstrap";
import useScreenType from "react-screentype-hook";
import { useAuth0 } from "@auth0/auth0-react";
import BaseService from "../service/BaseService";
import Loading from "./Loading";
import AppCookieBanner from "./AppCookieBanner";
import DashboardService from "../service/DashboardService";

export default function UserProfile(props) {
  const [user, setUser] = useState(undefined);
  const [defaultLPEntityCode, setDefaultLPEntityCode] = useState("");
  const [lpProfiles, setLPProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const screenType = useScreenType();
  
  const setActiveLPEntityCode = async (code) => {
    const token = await getAccessTokenSilently();
    let meta = user.meta;
    meta.defaultLPEntityCode = code;

    await BaseService.updateUserMeta(token, meta);

    loadData();
  };

  const checkConfiguration = async(configurationKey) => {
    const token = await getAccessTokenSilently();
    let conf = await DashboardService.getConfiguration(token);

    return conf?.find(
      (e) => e.name.toLowerCase() === configurationKey
    )?.meta?.value;
  };
  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await BaseService.getUser(token);
      let defaultCode = undefined;
      if (resp) {
        setUser(resp.data);
        if (resp?.data?.meta?.defaultLPEntityCode) {
          defaultCode = resp.data?.meta?.defaultLPEntityCode;
          setDefaultLPEntityCode(defaultCode);
        }
      }

      let resp2 = (await BaseService.getLPProfile(token)) || [];
      setLPProfiles(resp2);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!user) {
    return (
      <Container className={"mt-5 mb-5 h-100"}>
        <Loading />
      </Container>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <Container>
        <h1>Profile</h1>
        <Row>
          <Col
            className={`pb-5 d-flex flex-row ${
              screenType.isMobile
                ? "justify-content-center"
                : "justify-content-end pr-5"
            } `}
            md="6"
            lg="6"
            sm="12"
            xs="12"
          >
            <div className={"d-flex pt-5 flex-column"}>
              <img
                src={user.picture}
                alt="Profile"
                className="nav-user-profile rounded-circle"
                width={"96"}
              />
            </div>
          </Col>
          <Col className={"pt-5 px-5"} md="6" lg="6" sm="12" xs="12">
            <h4 className="text-bold">
              {user.given_name} {user.family_name}
            </h4>
            <span className={"text-muted"}>{user.email}</span>
            {/* <div className={"text-muted"}>
              Member Since: {moment(user.meta?.joined).format("MMM DD, YYYY")}
            </div> */}

            {lpProfiles.length <= 0 && (
              <div className="mt-3">No LP Entity Assigned</div>
            )}

            {lpProfiles.map((lp, index) => {
              return (
                <div key={index}>
                  <hr />

                  <div className={"hero-subtitle text-lightly-muted mt-3"}>LP ENTITY</div>
                  <div>{lp.companyName} </div>

                  <div className={"hero-subtitle text-lightly-muted mt-3"}>
                    SUBSCRIPTION EXPIRY DATE
                  </div>
                  <div>
                    {BaseService.getDateString(
                      lp.meta?.subscription?.expiredAt
                    )}
                  </div>
                  {!checkConfiguration("system-bypass-dd-credit") && (
                    <div>
                      <div className={"text-lightly-muted mt-3 text-uppercase"}>
                          <span>due diligence credits remaining</span>
                      </div>
                      <div>{lp.meta?.credits}</div>
                  </div>
                  )}
                </div>
              );
            })}
            <AppCookieBanner showButton={true} />
          </Col>
        </Row>
      </Container>
      {/* "meta": {
            "credits": 8,
            "joined": "2022-01-01",
            "subscription": {
                "expiredAt": "2024-01-01"
            }
        }, */}
    </Suspense>
  );
}
