import React, {useState, useEffect } from "react";
import "./Onboarding.scss";

import { 
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
} from "reactstrap";

import { Link } from "react-router-dom";

export const OnboardingKYC = (props) => {
  const { lpEntityCode, lpLegalName } = { ...props };
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const loadData = async () => {
    try {
      // if (!token) {
      //   return;
      // }
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div>
      <div className="onboarding-title pt-3">KYC Process</div>
      <div className="onboarding-body py-3">
        These steps are necessary to ensure compliance with legal and regulatory requirements. 
        The information collected during this registration process will be used for any future 
        investments you make with us. By following these steps, you can maintain the integrity 
        and security of your investments and streamline future investment processes. 
        This makes it quicker and easier to participate in new opportunities.
      </div>
      <div className="onboarding-body py-3">
        <div className="py-1 d-flex">
          <div md={1} className="px-2">
            <div class="number-icon">1</div>
          </div>
          <div>
            <div className="step-title padding-0">Subscription Document and Investment Questionnaire Template</div>
            <div className="step-body"><Link className="action-message padding-0">Let's start > </Link></div>
          </div>
        </div>
        <div className="py-1 d-flex">
          <div md={1} className="px-2">
            <div class="number-icon">2</div>
          </div>
          <div>
            <div className="step-title">Know Your Customer (KYC) and Anti-Money Laundering (AML) Process</div>
            <div className="step-body">Pending</div>
          </div>
        </div>
        <div className="py-1 d-flex">
          <div md={1} className="px-2">
            <div class="number-icon">3</div>
          </div>
          <div>
            <div className="step-title">Tax Form</div>
            <div className="step-body">Pending</div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default OnboardingKYC;
