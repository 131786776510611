export const ACCESS = {
    PROFILE: "profile",
    ADMIN: "admin",
    INVESTOR_DOCUMENTS: "investorDocuments",
    FUND_SIGNED_DOCUMENTS: "fundSignedDocuments",
    MANAGE_MEMBERSHIP: "manageMembership",
    REVIEW_FUNDS: "reviewFunds",
    INVESTMENT_DECISION: "investmentDecision",
  
}; 

export const INVESTING_ENTITY_DOC_TYPE = {
    INVESTING_ENTITY: "investingEntity",
    SUB_DOC: "subDoc",
    KYC: "kyc",
    TAX_FORM: "taxForm",
};

export const INVESTING_ENTITY_DOC_TYPE_MAP = {
    [INVESTING_ENTITY_DOC_TYPE.INVESTING_ENTITY]: {
        label: "Overall"
    },
    [INVESTING_ENTITY_DOC_TYPE.SUB_DOC]: {
        label: "Subscription Document"
    },
    [INVESTING_ENTITY_DOC_TYPE.KYC]: {
        label: "KYC"
    },
    [INVESTING_ENTITY_DOC_TYPE.TAX_FORM]: {
        label: "Tax Form"
    },
};


// current FATF countries with iso 3166-1 alpha-2 code
export const FATF_COUNTRY_ISO_3166_1_CODES = [
    "AR",
    "AU",
    "AT",
    "BE",
    "BR",
    "CA",
    "CN",
    "DK",
    "FI",
    "FR",
    "DE",
    "GR",
    "HK",
    "IS",
    "IN",
    "ID",
    "IE",
    "IL",
    "IT",
    "JP",
    "KR",
    "LU",
    "MY",
    "MX",
    "NL",
    "NZ",
    "NO",
    "PT",
    "SA",
    "SG",
    "ZA",
    "ES",
    "SE",
    "CH",
    "TR",
    "GB",
    "US"
    ]

export const INVESTING_ENTITY_TYPE = {
    INDIVIDUAL: "Individual",
    TRUST: "Trust",
    PARTNERSHIP: "Partnership",
    US_LEGAL_ENTITY: "US Legal Entity",
    FOREIGN_LEGAL_ENTITY: "Foreign Legal Entity",
};

export const INVESTING_ENTITY_TYPE_OPTIONS = [
    { value: INVESTING_ENTITY_TYPE.INDIVIDUAL, label: "Individual" },
    { value: INVESTING_ENTITY_TYPE.TRUST, label: "Trust" },
    { value: INVESTING_ENTITY_TYPE.PARTNERSHIP, label: "Partnership"},
    { value: INVESTING_ENTITY_TYPE.US_LEGAL_ENTITY, label: "US Legal Entity" },
    { value: INVESTING_ENTITY_TYPE.FOREIGN_LEGAL_ENTITY, label: "Foreign Legal Entity" },
  ];

export const LP_ENTITY_TYPE = {
    ALP: "ALP",
    FAMILY_OFFICE: "FO",
};

export const DOCUMENT_STATUS = {
    TO_BE_STARTED: "To be started",
    STARTED: "Started",
    PENDING_TO_REVIEW: "Pending to review",
    REVIEW_IN_PROGRESS: "Review in progress",
    REQUESTED_UPDATE: "Requested update",
    COMPLETED: "Completed",
  };

export const USER_ROLE = {
    AUTH_SIGNATORY: "AUTH_SIGNATORY",
    INVESTOR: "INVESTOR",
    MEMBERSHIP_MANAGER: "MEMBERSHIP_MANAGER",
    V0_ADMIN: "V0_ADMIN",
    BO_ADMIN: "BO_ADMIN",
}

export const USER_ROLE_OPTIONS = [
    { value: USER_ROLE.AUTH_SIGNATORY, label: "Authorized Signatory - making investment decisions", tag: "Authorized Signatory" },
    { value: USER_ROLE.INVESTOR, label: 'Investor - reviewing funds', tag: "Investor" },
    { value: USER_ROLE.MEMBERSHIP_MANAGER, label: 'Membership Manager - managing subscriptions and members', tag: "Membership Manager" },
  ];



export const USER_ROLE_ALP_DEFAULT = ['AUTH_SIGNATORY', 'INVESTOR'];

export const USER_TYPE = {
    V0_TEAM: "VT",
    LIMITED_PARTNER: "LP",
    BACK_OFFICE: "BO",
}