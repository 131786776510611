import BaseService from "./BaseService";


class MemberService {

  static async addMember(token, data) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/member/add`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

  static async updateMember(token, data, id) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/member/update/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

  static async removeMemberAndSendNotification(token, data, id) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/member/removeandnotify/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

  static async removeMember(token, data, id) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/member/remove/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }
}


export default MemberService;
