import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../UserContext";
import { 
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Modal,
  ModalBody,
  Row, 
  UncontrolledPopover,
  UncontrolledDropdown,
  PopoverBody,
  ButtonToolbar,
  ButtonGroup,
  FormGroup,
  Input,
} from "reactstrap";
import "./TeamMembers.scss";
import "./InvestingEntities.scss";
import DataTable from "react-data-table-component";
import {
  AddCircle,
  ArrowDown,
  Refresh,
  TextalignJustifycenter,
  UserAdd,
  UserEdit,
} from "iconsax-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsInfoCircle } from 'react-icons/bs';
import Select from 'react-select'; 
import { Form, Field, useForm, useField } from "react-final-form";
import FormStyles from "./TeamMembersFormStyles";
import Loading from "./Loading";
import { Fade } from "react-awesome-reveal";
import { 
  INVESTING_ENTITY_DOC_TYPE,
  INVESTING_ENTITY_DOC_TYPE_MAP,
  INVESTING_ENTITY_TYPE_OPTIONS, 
  DOCUMENT_STATUS 
} from "../constants";
import InvestingEntityService from "../service/InvestingEntityService";
import LPEntityService from "../service/LPEntityService";
import { getUSCountry, getFATFCountries, getCountryByCode } from "../utils/common";
import InternalNoteService from "../service/InternalNoteService";
import OnboardingService from "../service/OnboardingService";
import documentStatusLifecycle from "../assets/document-status-lifecycle.png";


export default function InvestingEntities(props) {

  const [context] = useContext(UserContext);
  
  const { getAccessTokenSilently } = useAuth0();

  const { editEnabled = true } = props;
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(props.modal);
  const [done, setDone] = useState(false);
  const [action, setAction] = useState(props.action, undefined);
  const [docType, setDocType] = useState(undefined);
  const [selectedInvestingEntity, setSelectedInvestingEntity] = useState(undefined);
  const [allLPEntities, setAllLPEntities] = useState([]);
  const [newOrUpdatedEntity, setNewOrUpdatedEntity] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [usCountry, setUSCountry] = useState(undefined);
  const fileInputRef = useRef(null);
  const [newStatus, setNewStatus] = useState(undefined);
  const [message, setMessage] = useState("");
  

  const investingEntityTypeOptions = INVESTING_ENTITY_TYPE_OPTIONS;

  const loadData = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      let resp = await InvestingEntityService.getAll(token);
      setData(resp);
      let lpEntities = await LPEntityService.getAll(token);
      setAllLPEntities(lpEntities?.map((entity) => ({
        label: entity.companyName,
        value: entity.code
      })));
      setCountries(getFATFCountries());
      setUSCountry(getUSCountry());
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  
  const selectAdapter = ({input, ...props}) => 
    
    <Select
      defaultValue={props.values ||props.initialValues || ""}
      {...input}
      {...props}
      styles={{
        control: (provided, state) => ({
          ...provided,
          border: state.isFocused ? "2px solid #ccc" : "1px solid #ccc",
          boxShadow: "none",
          "&:hover": {
          border: "2px solid #ccc",
          boxShadow: "none"
        }
        }),
      }}
    />

  const Interactivefield = ({ name, options, dependsOn, ...props }) => {
      const form = useForm();
      const { input, meta } = useField(name);
      const dependentField = useField(dependsOn)
  
      React.useEffect(() => {
        if (dependentField.input.value) {
          form.change(name, usCountry);
        }
      }, [dependentField.input.value])
  
      return (
        <Select
        defaultValue={props.values ||props.initialValues || ""}
        options={options}
        {...input}
        {...props}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? "2px solid #ccc" : "1px solid #ccc",
            boxShadow: "none",
            "&:hover": {
            border: "2px solid #ccc",
            boxShadow: "none"
          }
          }),
        }}
      />
  
      )
    }
  const StatusCell = ({ value, id, info }) => (
      <div id={id}>
        <span>{value} </span>
        <BsInfoCircle/>
        <UncontrolledPopover placement="right" target={id} className="popover">
          <PopoverBody>
          <div className="popover-message">{info}</div>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    );


  const takeAction = (selectedInvestingEntity, action, docType, newStatus) => {
    setSelectedInvestingEntity(selectedInvestingEntity);
    setAction(action);
    setDocType(docType);
    setNewStatus(newStatus);
    setModal(true);
  };
  const close = async () => {
    setDone(false);
    setModal(false);
    setNewOrUpdatedEntity(undefined);
    setNewStatus(undefined);
    loadData();  
    setLoading(false);
  };
  const confirmAction = async (
    action,
    values
  ) => {
    
    try{
      const token = await getAccessTokenSilently();
      if(action === "add" || action === "update" || action === "remove"){
        const data = {
          name: values?.name,
          entityType: values?.entityType?.value,
          lpEntityCode: values?.lpEntityCode?.value,
          registeredCountry: values?.registeredCountry?.value,
        };
        if (action === "add") {
          await InvestingEntityService.addInvestingEntity(token, data);
        } else if (action === "update") {
          await InvestingEntityService.updateInvestingEntity(token, data, selectedInvestingEntity.id, context.id);
        } else if (action === "remove") {
          await InvestingEntityService.removeInvestingEntity(token, data, selectedInvestingEntity.id, context.id);
        }
      }
      else if(action==="addNote" || action==="updateNote"){
        const data = {
          note: values?.note,
          type: docType,
          referenceId: selectedInvestingEntity.id,
          referenceModel: "InvestingEntity"
        };
        if (action === "addNote") {
          data.createdBy = context.id;
          await InternalNoteService.addInternalNote(token, data);
        } else if (action === "updateNote") {
          data.updatedBy = context.id;
          await InternalNoteService.updateInternalNote(token, data, values?.id);
        }
      }
      else if (action === "updateStatus") {
        await updateStatus(selectedInvestingEntity);
      }
    } catch(e) {
      console.error(e);
    } finally {
    }           
  };

  const updateStatus = async (investingEntity) => {
    const token = await getAccessTokenSilently();
    const data = {
      subDocStatus: newStatus,
    };
    return await InvestingEntityService.updateInvestingEntity(token, data, investingEntity.id);  
  }

  const handleFileChange = (investingEntity) => async (event) => {
    
    const file = event.target.files[0];
    if (file) {
      try {
        const token = await getAccessTokenSilently();
        if(investingEntity.subDocGUID){
          await OnboardingService.replaceSubDoc(token, file, investingEntity.subDocGUID);
        } else {
          await OnboardingService.uploadSubDoc(token, file, investingEntity.id);
        }
        await updateStatus(investingEntity, DOCUMENT_STATUS.PENDING_TO_REVIEW); 
        setMessage("File uploaded successfully");
      } catch (error) {
        setMessage("Error uploading file");
      }
    }
    setAction("uploadSubDoc");
    setModal(true);
    setDone(true);
  };

  const handleUploadDocument = () => {   
    fileInputRef.current.click();
  };
  const openSubDoc = async (investingEntity) => {
    const token = await getAccessTokenSilently();
    let resp = await OnboardingService.getPreAuthURL(token, investingEntity?.subDocGUID);
    if(resp && resp.PreAuthURL) {
      window.open(resp.PreAuthURL, '_blank');
    }
  }

  const getActionIcon = (act) => {
    const color = "#3e7cb1";
    if (act === "add") {
      return (
        <UserAdd
          size="80"
          color={"white"}
          style={{
            background: color,
            borderRadius: "50%",

            padding: 13,
          }}
        />
      );
    } else if (act === "update") {
      return (
        <UserEdit
          size="80"
          color={"white"}
          style={{ background: color, borderRadius: "50%", padding: 13 }}
        />
      );
    }
  };

  const getNotificationMessage = (action, docType) => {
    if (action === "add") {
      return (
        <div>
          <p>
            {newOrUpdatedEntity.name} is added to {newOrUpdatedEntity.lpEntityCode.label}.
          </p> 
        </div>
      );
    } else if (action === "update") {
      return (
         <div>
         {selectedInvestingEntity.name} has been updated.
         </div>
      );
    } else if (action === "remove") { return (
      <div className="py-5">
        {selectedInvestingEntity.name} has been removed from {selectedInvestingEntity.lpEntity.companyName}.
      </div>
      );
    } else if (action === "addNote") { return (
      <div className="py-5">
        {selectedInvestingEntity.name}'s {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label}'note has been added.
      </div>
      );
    } else if (action === "updateNote") { return (
      <div className="py-5">
        {selectedInvestingEntity.name}'s {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label}'note has been updated.
      </div>
      );
    } else if (action === "updateStatus") { return (
      <div className="py-5">
        {selectedInvestingEntity.name}'s {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label}'status has been updated.
      </div>
      );
    } else if (action === "uploadSubDoc") { return (
      <div className="py-5">
        {message}
      </div>
      );
    }

  };

  const checkRemoveInvestingEntityWarnings = () => {
    let warnings = [];
    
    return warnings;
  };

  const getRemoveConfirmationMessage = () => {
    let warnings = checkRemoveInvestingEntityWarnings();
    if (warnings?.length > 0) {
      return (
        <div>
          <div className="pb-3">
            {selectedInvestingEntity.firstName} {selectedInvestingEntity.lastName} cannot be removed from. For the following reason(s):
          </div>
          <Row>
          <Col ></Col>
          <Col xs="9">
            <div className="text-left">
            <ul>
            {warnings.map((warning, index) => (
              <li key={index}>{warning}</li>
            ))}
          </ul>
            </div>
          </Col>
          <Col></Col>
          </Row>
          {/* <ul>
            {warnings.map((warning, index) => (
              <li key={index}>{warning}</li>
            ))}
          </ul> */}
        </div>      
      );
    } else {
      return (
        <div>
          <b>{selectedInvestingEntity.name}</b> will be removed from <b>{selectedInvestingEntity.lpEntity.companyName}</b>.
        </div>
      );
    }
  };

  const getUpdateStatusMessage = () => {
      return (
        <div className="px-5 text-left">
          <b>{selectedInvestingEntity.name}</b>'s {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label} status will be updated to <b>{newStatus}</b>.
        </div>
      );
  };

  const customDataTableStyles = {
    rows: {
      style: {
        fontWeight: 200, // override the row height
      },
    }
  };
  

  const onSubmit = async (values) => {
    setLoading(true);
    setDone(true);
    setNewOrUpdatedEntity(values);
    await confirmAction(
      action.replace(/ /g, "-"),
      values
    );
  };

  const ExpandedComponent = ({ data }) => {
    return(
      data?.internalNote?.length > 0 && (
      <div>
        <Row className="note-title">Remarks</Row>
        {data?.internalNote?.map((note, index) => (
          <div>
          <Row className="note" key={index}>
            <Col className="flex">
              <div className="note-type">{INVESTING_ENTITY_DOC_TYPE_MAP[note.type].label}:</div>
              <div className="note-content">{note.note}</div>
            </Col>
          </Row>
          <Row className="note-timestamp" key={index}>
            <Col className="flex">
            {note.internalNoteUpdatedBy ? 
            `last updated on ${new Date(note.updatedAt).toLocaleString()} by ${note.internalNoteUpdatedBy.firstName} ${note.internalNoteUpdatedBy.lastName}` : 
            `created on ${new Date(note.createdAt).toLocaleString()} by ${note.internalNoteCreatedBy.firstName} ${note.internalNoteCreatedBy.lastName}`}
            </Col>
          </Row>
          </div>
        ))}
      </div>
      )
    )
  }

  

  const expandedByDefault = row => !!(row.internalNote?.length > 0);

  const columns = [
    {
      id: "lpeEntity",
      name: "LP Entity",
      selector: (row) => row.lpEntity.companyName,
      sortable: true,
      expandableRows: true,
      grow: 3,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      expandableRows: true,
      grow: 3,
    },
    {
      id: "registeredCountry",
      name: "Registered Country",
      sortable: true,
      grow: 3,
      selector: row => getCountryByCode(row.registeredCountry),
    },
    {
      id: "entityType",
      name: "Entity Type",
      sortable: true,
      grow: 3,
      selector: row => row.entityType,
    },
    {
      id: "subDocStatus",
      name: <div id="SuqbDocStatus">
      Sub Doc Status &nbsp;
      <BsInfoCircle />
      <UncontrolledPopover
        placement="bottom"
        target="SuqbDocStatus"
        className="popover"
      >
        <PopoverBody>
        <Row>
          <Col className="text-left pb-3">
            <div className="popover-message">
              <b>Sub Doc Status lifecycle:</b>
              <img src={documentStatusLifecycle} alt="Document Status Lifecycle" className="img-fluid mt-2 custom-image"  />
            </div>
          </Col>
        </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </div>,
      sortable: true,
      grow: 2, 
      selector: row => (
        <Badge className="progress-badge">{row.subDocStatus}</Badge>), 
    },
    {
      id: "subDocAction",
      name: "",
      sortable: true,
      width: '50px', 
      selector: row => (
        <UncontrolledDropdown className="rounded z-4">
            <DropdownToggle color={""}>
              <FontAwesomeIcon
                color={"rgba(51, 51, 51, 0.8)"}
                icon={faEllipsisV}
                className="mr-2"
                size="xs"

              />
            </DropdownToggle>
            <DropdownMenu className="btn-light">
              {(row.subDocStatus === (DOCUMENT_STATUS.TO_BE_STARTED) || 
              row.subDocStatus === (DOCUMENT_STATUS.STARTED)  ||
              row.subDocStatus === (DOCUMENT_STATUS.REQUESTED_UPDATE)
              ) && (
                // <>
                  <DropdownItem onClick={handleUploadDocument}>
                    Upload document
                  </DropdownItem>
                // </>
              )}
              {(row.subDocStatus === (DOCUMENT_STATUS.PENDING_TO_REVIEW)) && (
                <>
                  <DropdownItem
                    onClick={() => {
                      takeAction(row, "updateStatus", INVESTING_ENTITY_DOC_TYPE.SUB_DOC, DOCUMENT_STATUS.REVIEW_IN_PROGRESS);
                    }}
                  >
                    Start review
                  </DropdownItem>
                </>
              )}
              {(row.subDocStatus === (DOCUMENT_STATUS.REVIEW_IN_PROGRESS) ||
              row.subDocStatus === (DOCUMENT_STATUS.REQUESTED_UPDATE) ||
              row.subDocStatus === (DOCUMENT_STATUS.COMPLETED)) && (
                <>
                  <DropdownItem
                    onClick={() => {
                      openSubDoc(row);
                    }}
                  >
                    View document
                  </DropdownItem>
                  </>
              )}
              {(row.subDocStatus === (DOCUMENT_STATUS.PENDING_TO_REVIEW) ||
              row.subDocStatus === (DOCUMENT_STATUS.REVIEW_IN_PROGRESS)) && (
                <>
                  <DropdownItem
                    onClick={() => {
                      takeAction(row, "updateStatus", INVESTING_ENTITY_DOC_TYPE.SUB_DOC, DOCUMENT_STATUS.REQUESTED_UPDATE);
                    }}
                  >
                    Requested for more info
                  </DropdownItem>
                </>
              )}
              {(row.subDocStatus === (DOCUMENT_STATUS.REVIEW_IN_PROGRESS))
               && (
                <>
                  <DropdownItem
                    onClick={() => {
                      takeAction(row, "updateStatus", INVESTING_ENTITY_DOC_TYPE.SUB_DOC, DOCUMENT_STATUS.COMPLETED);
                    }}
                  >
                    Complete review
                  </DropdownItem>
                </>
              )}
              {(row.subDocStatus === (DOCUMENT_STATUS.COMPLETED)) && (
                <>
                  <DropdownItem
                     onClick={() => {
                      takeAction(row, "updateStatus", INVESTING_ENTITY_DOC_TYPE.SUB_DOC, DOCUMENT_STATUS.REQUESTED_UPDATE);
                    }}
                  >
                    Requested update
                  </DropdownItem>
                </>
              )}
              <DropdownItem
                  onClick={() => {
                    const noteAction = row.internalNote?.find(note => note.type === INVESTING_ENTITY_DOC_TYPE.SUB_DOC) ? "updateNote" : "addNote";
                    takeAction(row, noteAction, INVESTING_ENTITY_DOC_TYPE.SUB_DOC);
                  }}
                  >
                  {row.internalNote?.find(note => note.type === INVESTING_ENTITY_DOC_TYPE.SUB_DOC) ? (
                    `Update note`
                  ) : (
                    `Add note`
                    
                  )}
                  </DropdownItem>
                  <DropdownItem
                    disabled
                    onClick={() => {
                      takeAction(row, "viewHistory", INVESTING_ENTITY_DOC_TYPE.SUB_DOC);
                    }}
                  >
                    View history
                  </DropdownItem>
            </DropdownMenu>
            <input
              type="file"
              ref={fileInputRef}
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={handleFileChange(row)}
            />
          </UncontrolledDropdown>
      ), 
    }
    
  ];

  if (editEnabled) {
    columns.push({
      id: "investingEntityAction",
      name: "",
      ignoreRowClick: true,
      selector: (investingEntity) => {
        return (
          <UncontrolledDropdown className="rounded z-3">
            <DropdownToggle color={""}>
              <TextalignJustifycenter/>
            </DropdownToggle>
            <DropdownMenu className="btn-light">
              <DropdownItem
                onClick={() => {
                  takeAction(investingEntity, "update");
                }}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  takeAction(investingEntity, "remove");
                }}
              >
                Remove
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  takeAction(investingEntity, "addNote", INVESTING_ENTITY_DOC_TYPE.INVESTING_ENTITY);
                }}
              >
                Add Notes
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    });
  }

  return (
    <div>
      <Modal
        backdrop={"static"}
        centered={true}
        isOpen={modal}
        toggle={close}
        fullscreen={"sm"}
        fade={true}
        size={done?"md":"lg"}
        scrollable={true}
      >
    
        <ModalBody className={"bg-light text-center h-50 py-5"}>
        
        {done ? (
            <div className="text-center">
              <Fade>
                <div className={"align-items-center justify-content-center py-3"}>
                  {getActionIcon(action)}
                </div>
              </Fade>
              <div className="py-1">
                <h4>Done!</h4>
              <span className={"text-muted text-light"}>
                {getNotificationMessage(action, docType)}
              </span>
              </div>
              <Fade cascade className="pt-3">
                </Fade>
                <Button
                  className="mx-2 base-button ternary-button"
                  onClick={close}
                >
                  Close
                </Button>
                {action === "updateStatus" && (
                  <Button
                  className="mx-2 base-button primary-button"
                  onClick={() => {
                    close();
                    if(action === "updateStatus") {
                      openSubDoc(selectedInvestingEntity);
                    }
                  }}
                >
                  View document
                </Button>
                )}
            </div>
          ) : null}

        {!done && (action==="update" || action==="add") && (
            <div className="px-1">
              {(action === "update") && (<div><h1 className="text-left">Update Investing Entity</h1></div>)}
              {(action === "add") && (<div><h1 className="text-left">Add New Investing Entity</h1></div>)}   
              <FormStyles>
                <Form
                  onSubmit={(values) => onSubmit(values)}
                  initialValues={{
                    name: action === "update" ? selectedInvestingEntity?.name : "",
                    entityType: action === "update" ? INVESTING_ENTITY_TYPE_OPTIONS.find(type => type?.value === selectedInvestingEntity?.entityType) : "",
                    registeredCountry: action ==="update" ? 
                      (selectedInvestingEntity?.entityType === "US Legal Entity" ? countries.find(country => country?.value === "US")
                      : countries.find(country => country?.value === selectedInvestingEntity?.registeredCountry))
                      : "",
                    lpEntityCode: action === "update" ? allLPEntities.find(entity => entity?.value === selectedInvestingEntity?.lpEntityCode) : "",
                  }}
                  validate={(values) => {
                    const errors = {};
                      let isChanged = false;
                      if (values?.name !== selectedInvestingEntity?.name) {
                        isChanged = true;
                      }
                      if (values?.entityType?.value !== selectedInvestingEntity?.entityType) {
                        isChanged = true;
                      }
                      if (values?.registeredCountry?.value !== selectedInvestingEntity?.registeredCountry) {
                        isChanged = true;
                      }
                      if (values?.lpEntityCode?.value !== selectedInvestingEntity?.lpEntityCode) {
                        isChanged = true;
                      }

                      if (action==="update" && !isChanged) {
                        errors.overall = "No changes made";
                      }
                      if (!values?.name) {
                        errors.name = "Required";
                      }
                      if (!values?.entityType) {
                        errors.entityType = "Required";
                      }
                      if (!values?.lpEntityCode) {
                        errors.lpEntityCode = "Required";
                      }

                    return errors;
                  }}
                  render={({
                    errors,
                    handleSubmit,
                    submitting,
                    valid,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      
                      {loading && <Loading />}
                      {(action === "add" || action === "update") && (
                        
                        <>
                         <Row>
                          <div className="form-fields">
                              <Label>Investing Entity Name</Label>
                              <Field
                              name="name"
                              component="input"  
                              // readOnly={action === "update"}                   
                              placeholder={(action === "add" ? "Investing Entity Name" : "")}
                            /> 
                            </div> 
                            <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.name}
                              </Fade>
                          </div>                       
                          </Row>  
                          <Row sm="2" xs="1">
                            <Col className="form-fields">
                              <Label>Entity Type</Label>
                              <Field
                              name="entityType"
                              component={selectAdapter} 
                              options={investingEntityTypeOptions} 
                              // value={selectedEntityType}
                              className="basic-multi-select text-left team-members mt-0"
                              classNamePrefix="select"
                            /> 
                            
                              <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.entityType}
                              </Fade>
                              </div>
                            </Col>
                            <Col className="form-fields">
                              <Label>Registered Country</Label>
                                                       
                              <Interactivefield
                              name="registeredCountry"
                              component={selectAdapter} 
                              options={values.entityType?.value !== "US Legal Entity" ? countries:[usCountry]} 
                              className="basic-multi-select text-left team-members mt-0"
                              classNamePrefix="select"
                              dependsOn={"entityType"}
                            /> 
                            
                            <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.registeredCountry}
                              </Fade>
                            </div>
                            </Col>
                          </Row>
                          <Row>
                          <div className="form-fields">
                              <Label>Associated LP Entity</Label>
                              <Field
                                name="lpEntityCode"
                                component={selectAdapter}
                                options={allLPEntities}
                                className="basic-multi-select text-left team-members mt-0"
                                classNamePrefix="select"
                              />
                          </div>
                          <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.lpEntityCode}
                              </Fade>
                          </div>
                          </Row>
                        </>
                      )}
                      <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.overall}
                              </Fade>
                          </div>
                      <Button
                        onClick={close}
                        className="mx-2 base-button ternary-button"
                      >
                        Cancel
                      </Button>

                      {!done && (
                        <Button
                          disabled={!valid || loading  || submitting}
                          type="submit"
                          className={"base-button mx-2 primary-button"}
                        >
                          {action === "add" ? "Add" : "Update"}
                        </Button>
                      )}
                    </form>
                  )}
                >
                  
                </Form>
              </FormStyles>
            </div>
          )}
        {!done && action==="remove" && (
          <div className="px-1">
          <div><h1 className="text-left">Remove Member</h1></div>
          <span className={"text-muted text-light"}>
                <div className="py-5">
                  {getRemoveConfirmationMessage()}
                </div>
              </span>
          <FormStyles>
            <Form
              onSubmit={(values) => onSubmit(values)}
              render={({
                handleSubmit,
                submitting,
                valid,
              }) => (
                <form onSubmit={handleSubmit}>
                  {loading && <Loading />}
                  
                  <Button
                    onClick={close}
                    className="mx-2 base-button ternary-button"
                  >
                    Cancel
                  </Button>

                  {checkRemoveInvestingEntityWarnings().length < 1 && (
                  <Button
                      disabled={!valid || loading  || submitting}
                      type="submit"
                      className={"base-button mx-2 primary-button"}
                    >
                      Confirm
                  </Button>
                  )}
                </form>
              )}
            ></Form>
          </FormStyles>
        </div>
        )}
        {!done && action==="updateStatus" && (
          <div className="px-1">
          <div><h4 className="text-left">Update {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label} Status</h4></div>
          <span className={"text-muted text-light"}>
                <div className="py-5">
                  {getUpdateStatusMessage()}
                </div>
              </span>
          <FormStyles>
            <Form
              onSubmit={(values) => onSubmit(values)}
              render={({
                handleSubmit,
                submitting,
                valid,
              }) => (
                <form onSubmit={handleSubmit}>
                  {loading && <Loading />}
                  <Button
                    onClick={close}
                    className="mx-2 base-button ternary-button"
                  >
                    Cancel
                  </Button>
                  <Button
                      disabled={!valid || loading  || submitting}
                      type="submit"
                      className={"base-button mx-2 primary-button"}
                    >
                      Confirm
                  </Button>
                </form>
              )}
            ></Form>
          </FormStyles>
        </div>
        )}
        {!done && (action==="addNote" || action==="updateNote") && (
            <div className="px-1">
              {(action === "addNote") && (<div><h1 className="text-left">Add Note to {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label}</h1></div>)}
              {(action === "updateNote") && (<div><h1 className="text-left">Update {INVESTING_ENTITY_DOC_TYPE_MAP[docType].label} Note</h1></div>)}   
              <FormStyles>
                <Form
                  onSubmit={(values) => onSubmit(values)}
                  initialValues={{
                    note: selectedInvestingEntity?.internalNote?.find(note => note.type === docType)?.note || "",
                    id: selectedInvestingEntity?.internalNote?.find(note => note.type === docType)?.id || "",
                  }}
                  validate={(values) => {
                    const errors = {};
                      let isChanged = false;
                      if (values?.note !== selectedInvestingEntity?.internalNote?.find(note => note.type === docType)?.note) {
                        isChanged = true;
                      }

                      if (action==="updateNote" && !isChanged) {
                        errors.overall = "No changes made";
                      }

                    return errors;
                  }}
                  render={({
                    errors,
                    handleSubmit,
                    submitting,
                    valid,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      
                      {loading && <Loading />}
                      {(
                        
                        <>
                         <Row>
                          <div className="form-fields">
                              <Label>{INVESTING_ENTITY_DOC_TYPE_MAP[docType]?.label} note of {selectedInvestingEntity.name}</Label>
                              <Field
                              name="note"
                              component="textarea"  
                              placeholder={(action === "addNote" ? ([INVESTING_ENTITY_DOC_TYPE_MAP[docType]]?.label): "")}
                            /> 
                          </div> 
                          <div>
                            <Field type="hidden" name="id" component="input"/>
                          </div>
                            <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.name}
                              </Fade>
                          </div>                       
                          </Row>  
                        </>
                      )}
                      <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.overall}
                              </Fade>
                          </div>
                      <Button
                        onClick={close}
                        className="mx-2 base-button ternary-button"
                      >
                        Cancel
                      </Button>

                      {!done && (
                        <Button
                          disabled={!valid || loading  || submitting}
                          type="submit"
                          className={"base-button mx-2 primary-button"}
                        >
                          {action === "addNote" ? "Add" : "Edit"}
                        </Button>
                      )}
                    </form>
                  )}
                >
                  
                </Form>
              </FormStyles>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Container className="pt-2 team-members" fluid>
        <ButtonToolbar>
          <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  loadData();
                }}
              >
                <Refresh color="#FFF" />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  // setIsEditing(false);
                  // showFormData({});
                  takeAction(undefined, "add", INVESTING_ENTITY_DOC_TYPE.OVERALL);
                }}
              >
                <AddCircle />
              </Button>
            </ButtonGroup>
        </ButtonToolbar>
      </Container>
      
      <Container className="pb-3 team-members h-100">
        <DataTable
          className="pb-3 team-members h-100"
          defaultSortFieldId={2}
          columns={columns}
          data={data}
          highlightOnHover
          sortIcon={<ArrowDown className="mx-3"/>}
          emptyComponent={<div></div>}
          customStyles={customDataTableStyles}
          responsive={true}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandableRowExpanded={expandedByDefault}
          pagination // Enable pagination
          paginationPerPage={10} // Set items per page
          paginationRowsPerPageOptions={[5, 10, 20]} // Options for items per page
          selectableRows // Enable row selection
          selectableRowSelected={row => selectedInvestingEntity && selectedInvestingEntity.id === row.id} // Highlight selected row
          onSelectedRowsChange={selectedRows => setSelectedInvestingEntity(selectedRows.selectedRows[0])} // Handle row selection
          // Add filtering functionality if needed
        />
      </Container>
   </div>
    
  );
}
