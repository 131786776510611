import React, {useState, useEffect } from "react";
import "./Onboarding.scss";
import {  
  DOCUMENT_STATUS 
} from "../constants";
import { Link } from "react-router-dom";

export const OnboardingIntro = (props) => {
  const { goToStep, selectedItemId, investingEntity} = { ...props };
  

  const loadData = async () => {
    try {
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div>
      {investingEntity && (
      <div className="d-flex pt-0 h-100">
        <div className="status-title">Onboarding Status: </div>
        <div className="status ml-2">
            {(investingEntity?.subDocStatus === DOCUMENT_STATUS.TO_BE_STARTED) ?
              "Pending" : (investingEntity?.subDocStatus === DOCUMENT_STATUS.COMPLETED) ? 
              "Completed" : "Completed"}
        </div>
      </div>
      )}
      <div className="onboarding-title pt-3">The onboarding process includes 3 simple steps</div>
      <div className="onboarding-body py-3">
      For each entity investing in V0 funds, it is necessary to complete these steps to comply with legal and regulatory requirements. 
      The information collected during this registration process will be used for <b>future investments</b>. By following these steps, 
      the integrity and security of investments can be maintained, and future investment processes streamlined. 
      This makes it quicker and easier to participate in new opportunities.
      </div>
      <div className="onboarding-body py-3">
        <div className="py-1 d-flex">
          <div md={1} className="px-2">
            <div class="number-icon">1</div>
          </div>
          <div>
            <div className="step-title padding-0">Complete Subscription Document and Investment Questionnaire Template</div>
            <div className="step-body">
              <div>
              V0 team will review the template to ensure all the necessary information is ready for future investment.
              </div>
              {investingEntity && (
                <Link 
                  className="action-message padding-0"
                  onClick={() => goToStep('subdoc', selectedItemId, investingEntity)}
                  >
                  {(investingEntity?.subDocStatus === DOCUMENT_STATUS.TO_BE_STARTED) ?
                  `Let's start >` : (investingEntity?.subDocStatus !== DOCUMENT_STATUS.COMPLETED) ? `Let's Continue >` : investingEntity?.subDocStatus}
                </Link>
              )}
              </div>
          </div>
        </div>
        <div className="py-1 d-flex">
          <div md={1} className="px-2">
            <div class="number-icon">2</div>
          </div>
          <div>
            <div className="step-title">Proceed with Know Your Customer (KYC) and Anti-Money Laundering (AML) Review Process</div>
            <div className="step-body">
            Copies of the required documents based on the entity's type and registered country will be collected at this step. 
            </div>
            {investingEntity && (
              <div className="step-body">Pending</div>
            )}
          </div>
        </div>
        <div className="py-1 d-flex">
          <div md={1} className="px-2">
            <div class="number-icon">3</div>
          </div>
          <div>
            <div className="step-title">Complete W-9/W-8 Tax Form</div>
            <div className="step-body">To ensure compliance with IRS regulations for U.S. and foreign persons and entities.</div>
            {investingEntity && (
              <div className="step-body">Pending</div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default OnboardingIntro;
