
export function getFATFCountries () {
  var iso3166 = require("iso-3166-1-alpha-2")
    const FATF_COUNTRIES = require("../constants").FATF_COUNTRY_ISO_3166_1_CODES;
    return FATF_COUNTRIES.map((countryCode) => ({
      value: countryCode,
      label: iso3166.getCountry(countryCode)
    }));
  };

  export function getUSCountry () {
    var iso3166 = require("iso-3166-1-alpha-2")
      return {
        value: "US",
        label: iso3166.getCountry("US")
      };
    };

  export function getCountryByCode (code) {
      var iso3166 = require("iso-3166-1-alpha-2")
      return iso3166.getCountry(code);
  };
