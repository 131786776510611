import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  ElementPlus,
  DollarCircle,
  Money,
  SaveAdd,
  AddCircle,
} from "iconsax-react";
import {
  Container,
  Button,
  Modal,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
  Row,
  Col,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import VCFundService from "../service/VCFundService";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../UserContext";
import { Fade } from "react-awesome-reveal";
import { Form, Field } from "react-final-form";
import FormStyles from "./FundActionFormStyles";
import {
  ArrowDown,
  DocumentText1,
  NoteAdd,
  Reserve,
  Notification,
  Information,
  RecordCircle,
  Message2,
  MessageCircle,
} from "iconsax-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NumericInput from "react-numeric-input";

import Loading from "./Loading";
import mitt from "mitt";
import BaseService from "../service/BaseService";
import LogoBadge from "./LogoBadge";
import handShake from "../assets/Business-Handshake.png";
import { checkIsAuthorized } from "../utils/authorization";
import { ACCESS } from "../constants";

const emitter = mitt();

const FundReportActions = (props) => {
  const {
    dd = [],
    fundCategory,
    userId,
    lpEntityCode,
    vcFundId,
    fundName = "",
    vcFundActions = [],
    tier = 0,
    align = "center",
    fund = {},
    spotsLeftLocation = "center",
    isALP = false,
  } = props;

  const { getAccessTokenSilently } = useAuth0();
  const [modal, setModal] = useState(false);
  const [changingMind, setChaningMind] = useState(false);
  const [newVcFundActions, setNewVcFundActions] = useState([]);
  const [action, setAction] = useState(undefined);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [context] = useContext(UserContext);
  
  useEffect(() => {
    const handleStateChange = (newValue) => {
      // Handle state change
      loadData();
    };
    emitter.on("stateChange", handleStateChange);

    return () => {
      emitter.off("stateChange", handleStateChange);
    };
  }, []);

  const close = async () => {
    setDone(false);
    setModal(false);
  };

  const isAuthorized = (accessNames) => {
    return checkIsAuthorized(context, accessNames);
  }
  const fundMin = () => {
    //voCheckSize
    if (fund?.meta?.voCheckSize) {
      return parseInt(fund.meta.voCheckSize);
    } else {
      return parseInt(fundCategory?.meta?.min);
    }
  };

  const getActionIcon = (act) => {
    const color = "#3e7cb1";

    if (done) {
      return <LogoBadge />;
    }
    if (act === "Add to Waitlist") {
      return (
        <AddCircle
          size="80"
          color={"white"}
          style={{
            background: color,
            borderRadius: "50%",

            padding: 13,
          }}
        />
      );
    } else if (act === "Ask for Introduction") {
      return (
        <MessageCircle
          size="80"
          color={"white"}
          style={{ background: color, borderRadius: "50%", padding: 13 }}
        />
      );
    } else if (act === "Interested in updates") {
      return (
        <RecordCircle
          size="80"
          color={"white"}
          style={{ background: color, borderRadius: "50%", padding: 13 }}
        />
      );
    } else if (act === "Pre-Commit") {
      return (
        <div
          style={{
            width: "100px",
            height: "100px",
            margin: "auto",
            borderRadius: "50%",
            borderWidth: "0px",
            backgroundColor: "#3e7cb1",
          }}
        >
          <img
            title="handShake"
            alt="handShake"
            src={handShake}
            width={80}
            style={{
              padding: "15px",
              marginTop: "15px",
            }}
          />
        </div>
      );
    } else if (act === "Commit") {
      return (
        <div
          style={{
            width: "100px",
            height: "100px",
            margin: "auto",
            borderRadius: "50%",
            borderWidth: "0px",
            backgroundColor: "#3e7cb1",
          }}
        >
          <img
            title="handShake"
            alt="handShake"
            src={handShake}
            width={80}
            style={{
              padding: "15px",
              marginTop: "15px",
            }}
          />
        </div>
      );
    } else if (act === "Not Interested") {
      return (
        <RecordCircle
          size="80"
          color={"white"}
          style={{ background: color, borderRadius: "50%", padding: 13 }}
        />
      );
    } else {
      return <LogoBadge />;
    }
  };

  const getActionButton = (action, id, reverse, needToCheckFundRaisingStatus) => {

    let applied = actionApplied(action);
    if (loading) {
      return <Loading />;
    }

    if (needToCheckFundRaisingStatus && fund?.fundRaisingStatus !== "Started") {
      return;
    }

    return (
      <Col>
        <div
          style={{
            alignContent: "right",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {action === "Commit" &&
            !actionApplied("Not Interested") &&
            fund?.meta?.spotsLeftMessage &&
            fund?.fundRaisingStatus === "Started" &&
            spotsLeftLocation === "right" && (
              <Button className={"base-button info-button text-end pt-3"}>
                {fund?.meta?.spotsLeftMessage}
              </Button>
            )}
  
          <Button
            id={id}
            type="button"
            className={`${
              reverse ? "ternary-button" : "primary-button"
            } base-button mx-0 my-1`}
            disabled={applied}
            onClick={() => {
              if(isAuthorized([ACCESS.INVESTMENT_DECISION]))
                takeAction(action);
            }}
          >
            {action}
          </Button>
          {!isAuthorized([ACCESS.INVESTMENT_DECISION]) && (
            <UncontrolledPopover
              placement="bottom"
              target={id}
              trigger="focus"
            >
              <PopoverBody
                className="text-lightly-muted text-light small"
              >
                Only authorized signatory can perform this action.
              </PopoverBody>
            </UncontrolledPopover>
           )}
        </div>
      </Col>
    );
  };

  const getLabelButton = (label, reverse) => {
    if (loading) {
      return <Loading />;
    }
    return (
      <Col>
        <Button
          className={`${
            reverse ? "ternary-button" : "primary-button"
          } base-button mx-0 my-1`}
          disabled={true}
        >
          {label}
        </Button>
      </Col>
    );
  };

  const changeYourMind = () => {
    return (
      <Button
        className="m-1 text-muted text-light base-button bg-transparent border-0 primary-link"
        onClick={() => {
          setChaningMind(true);
        }}
      >
        Click to reconsider
      </Button>
    );
  };

  const loadData = async () => {
    const token = await getAccessTokenSilently();

    let d = await VCFundService.getFundByName(token, fundName);
    setNewVcFundActions(d.vcFundActions);
  };

  const takeAction = (action) => {
    setAction(action);
    setModal(true);
  };

  const actionApplied = (action) => {
    if (changingMind) {
      return false;
    }
    return (
      newVcFundActions
        .concat(vcFundActions)
        .findIndex(
          (ac) => ac.action.replace(/ /g, "-") === action.replace(/ /g, "-")
        ) !== -1
    );
  };

  const confirmAction = async (
    token,
    fundName,
    lpEntityCode,
    action,
    values
  ) => {
    setChaningMind(false);

    await VCFundService.applyFundActionByName(
      token,
      fundName,
      lpEntityCode,
      action,
      values
    );
  };

  const getText = (fund) => {
    const notDoneMappping = {
      "Not Interested": {
        header: "Thank you for informing us of your decision",
        text: "We will keep you updated on future selections.",
      },
      "Commit-ALP": {
        header: "Commit to Invest",
        text: `Please confirm your commitment of ${BaseService.formatMoney(
          fund?.meta?.voCheckSize
        )} in ${fund.meta?.titleName || ""} ${fund.meta?.fundName || ""}.`,
      },
      Commit: {
        header: "Commit to Invest",
        text: "Please indicate the commit amount",
      },
      "Pre-Commit": {
        header: "Reserve Your Spot",
        text: "Please indicate the pre-commit amount for your reservation.",
      },
      "Interested in updates": {
        header: "Interested in updates",
        text: "We will keep you informed of updates for this fund.",
      },
      "Ask for Introduction": {
        header: "Ask for Introduction",
        text: "We will introduce you to the general partner (GP) of this fund for direct investment.",
      },
      "Add to Waitlist": {
        header: "Add to Waitlist",
        text: "",
      },
    };

    const doneMappping = {
      "Not Interested": {
        header: "Thank You",
        text: "We invite you to view the Fund Gallery for more options.",
      },

      Commit: {
        header: "Thank you",
        text: "We will be in touch with you shortly to discuss the details.",
      },
      "Pre-Commit": {
        header: "Thank you",
        text: "We will keep you informed of updates for this fund.",
      },
      "Interested in updates": {
        header: "Thank you",
        text: "We will keep you informed of updates for this fund.",
      },
      "Ask for Introduction": {
        header: "Thank you",
        text: "We will be in touch with you to discuss the details.",
      },
      "Add to Waitlist": {
        header: "Add to Waitlist",
        text: "We will keep you informed of updates for this fund.",
      },
    };

    let mapping = done ? doneMappping : notDoneMappping;
    let theAction;
    if (isALP) {
      theAction = mapping[action + "-ALP"] || mapping[action];
    } else {
      theAction = mapping[action];
    }

    let header = theAction?.header;
    let text = theAction?.text;
    return (
      <div className="py-1">
        <h4>{header}</h4>
        <span className={"px-3 py-0 text-muted text-light"}>{text}</span>
      </div>
    );
  };

  const required = (value) => (value ? undefined : "Required");
  const minValue = (min) => (value) =>
    isNaN(value) || value >= min
      ? undefined
      : `Minimum ${action.toLowerCase()} amount is ${BaseService.formatMoney(
          min
        )}.`;

  const maxValue = (max) => (value) =>
    isNaN(value) || value <= max
      ? undefined
      : `The maximum ${action.toLowerCase()} amount that V0 currently accepts is ${BaseService.formatMoney(
          max
        )}.`;

  const errorMsg = (value) => {
    if (action === "Commit" && value > fundCategory.meta?.max) {
      return `The maximum commit amount for this fund is ${BaseService.formatMoney(
        fundCategory.meta.max
      )}. Please contact V0 team for more information.`;
    } else if (action === "Pre-Commit" && value > fundCategory.meta?.max) {
      return `The maximum pre-commit amount for this fund is ${BaseService.formatMoney(
        fundCategory.meta?.max
      )}. Please contact V0 team for more information.`;
    }
  };

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

  // special tier 1 case for dd unlock
  if (
    dd &&
    dd.length <= 0 &&
    (tier.toString() === "1" || tier.toString() === "2")
  ) {
    return;
  }

  return (
    <div>
      <Modal
        style={{ height: "75vh" }}
        backdrop={"static"}
        centered={true}
        isOpen={modal}
        toggle={close}
        fullscreen={"sm"}
        fade={true}
        size={"md"}
        scrollable={true}
      >
        {/* <ModalHeader toggle={close} className="bg-light">
          {fundName}
        </ModalHeader> */}
        <ModalBody className={"bg-light text-center h-75 py-5"}>
          <Fade>
            <div className={"align-items-center justify-content-center py-3"}>
              {getActionIcon(action)}
            </div>
          </Fade>
          {/* {action} */}
          {getText(fund)}

          {done ? (
            <Fade cascade className="pt-3">
              <Button
                className="mx-0 my-1 base-button primary-button"
                onClick={close}
              >
                Close
              </Button>
            </Fade>
          ) : null}
          {!done && (
            <div className="py-3 px-1">
              <FormStyles>
                <Form
                  onSubmit={async (values, { setSubmitting }) => {
                    const token = await getAccessTokenSilently();
                    try {
                      setLoading(true);

                      await confirmAction(
                        token,
                        fundName,
                        lpEntityCode,
                        action.replace(/ /g, "-"),
                        values
                      );
                    } catch (e) {
                    } finally {
                      await loadData();
                      emitter.emit("stateChange", new Date());
                      setLoading(false);
                      setDone(true);
                    }
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (isALP) {
                      return true;
                    }
                    if (action.indexOf("Commit") !== -1) {
                      if (!values.amount && action.indexOf("Commit") !== -1) {
                        errors.name = "Required";
                      }

                      if (
                        fundCategory.meta?.max &&
                        values.amount > fundCategory.meta?.max
                      ) {
                        errors.name = "Too High";
                      }

                      if (fundMin() && values.amount < fundMin()) {
                        errors.name = "Too Low";
                      }
                    }

                    return errors;
                  }}
                  initialValues={{
                    amount: isALP ? fundMin() : "",
                    comments: "",
                  }}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {loading && <Loading />}
                      {(action === "Commit" || action === "Pre-Commit") && (
                        <>
                          <div className={isALP ? "d-none" : "d-block"}>
                            {action === "Commit" ? "Commit" : "Pre-commit"}{" "}
                            Amount:{" "}
                            {values?.amount &&
                              BaseService.formatMoney(values?.amount)}
                          </div>

                          <div
                            className={isALP ? "d-none" : "d-block form-fields"}
                          >
                            <Field
                              validate={composeValidators(
                                required,
                                minValue(fundMin()),
                                maxValue(fundCategory.meta?.max)
                              )}
                              name="amount"
                              render={({ input, meta }) => (
                                <div>
                                  <NumericInput
                                    min={fundMin() || 0}
                                    step={10000}
                                    precision={0}
                                    snap
                                    {...input}
                                  />
                                  <div style={{ minHeight: "100px" }}>
                                    <div className="text-muted pt-2">
                                      Minimum commit amount is{" "}
                                      {BaseService.formatMoney(fundMin())}.
                                    </div>

                                    {meta.dirty && values?.amount && (
                                      <div className="text-danger">
                                        <Fade>{errorMsg(values.amount)}</Fade>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </>
                      )}

                      {action === "Not Interested" && (
                        <div className={"form-fields pb-2 px-3"}>
                          <Field
                            name="comments"
                            render={({ input, meta }) => (
                              <textarea
                                {...input}
                                rows="2"
                                placeholder="Please tell us why"
                              />
                            )}
                          />
                        </div>
                      )}
                      <Button
                        onClick={close}
                        className="mx-2 base-button ternary-button"
                      >
                        Cancel
                      </Button>

                      {!done && (
                        <Button
                          disabled={!valid || loading}
                          type="submit"
                          className={"base-button mx-2 primary-button"}
                        >
                          Confirm
                        </Button>
                      )}
                    </form>
                  )}
                ></Form>
              </FormStyles>
            </div>
          )}
        </ModalBody>
      </Modal>
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          margin: "auto",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: align === "right" ? "flex-end" : "center",
        }}
      >
        {actionApplied("Commit") && (
          <Badge className={`TierBadge  Tier1Badge  LargerBadge my-0`} pill>
            Commitment Made
          </Badge>
        )}

        {!actionApplied("Commit") && actionApplied("Not Interested") && (
          <Badge className={`TierBadge LargerBadge MutedBadge my-0`} pill>
            Not Interested
          </Badge>
        )}

        {actionApplied("Ask for Introduction") && (
          <Badge className={`TierBadge LargerBadge  my-0`} pill>
            Requested Introduction
          </Badge>
        )}

        {actionApplied("Add to Waitlist") && (
          <Badge className={`TierBadge LargerBadge  my-0`} pill>
            On Waitlist
          </Badge>
        )}
      </div>

      <Container
        style={{
          //width: "content-fit",
          justifyContent: align === "right" ? "flex-end" : "center",
          flexDirection: "column",
        }}
      >
        {!actionApplied("Commit") &&
          fund?.meta?.spotsLeftMessage &&
          fund?.fundRaisingStatus === "Started" &&
          spotsLeftLocation === "center" && (
            <Row className="text-align-center" style={{ alignItems: "center" }}>
              <Col>
                <Button className={"base-button info-button pt-3"}>
                  {fund?.meta?.spotsLeftMessage}
                </Button>
              </Col>
            </Row>
          )}

        {!actionApplied("Commit") &&
          actionApplied("Not Interested") &&
          fund?.meta?.spotsLeftMessage &&
          fund?.fundRaisingStatus === "Started" &&
          spotsLeftLocation === "right" && (
            <Row className="text-align-center" style={{ alignItems: "center" }}>
              <Col>
                <Button className={"base-button info-button pt-3 pb-0 m-0"}>
                  {fund?.meta?.spotsLeftMessage}
                </Button>
              </Col>
            </Row>
          )}

        <Row
          className="text-align-center"
          style={{
            flexWrap: "nowrap",
            alignItems: "flex-end",
          }}
        >
          {!actionApplied("Commit") &&
            (tier.toString() === "1" || tier.toString() === "2") && (
              <>
                {!actionApplied("Not Interested") &&
                  getActionButton("Not Interested", "NotInterested", true, true)}

                {!actionApplied("Not Interested") &&
                  getActionButton("Commit", "Commit", false, true)}
              </>
            )}

          {/* {tier.toString() === "3" && (
            <>
              {!actionApplied("Ask for Introduction")
                ? getActionButton("Ask for Introduction", false)
                : null}
            </>
          )} */}

          {tier.toString() === "6" && (
            <>
              {!actionApplied("Add to Waitlist")
                ? getActionButton("Add to Waitlist", "AddToWaitlist", false, false)
                : null}
            </>
          )}
        </Row>
      </Container>
      <Container
        fluid
        style={{
          display: "flex",
          justifyContent: align === "right" ? "flex-end" : "center",
        }}
      >
        {!actionApplied("Commit") &&
          actionApplied("Not Interested") &&
          !changingMind &&
          changeYourMind()}
      </Container>
    </div>
  );
};

export default FundReportActions;
