import React, { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import BaseService from "../service/BaseService";

// core styles shared by all of react-notion-x (required)
import "react-notion-x/src/styles.css";

// used for code syntax highlighting (optional)
import "prismjs/themes/prism-tomorrow.css";

// used for rendering equations (optional)
import "katex/dist/katex.min.css";

import { NotionRenderer } from "react-notion-x";
import { Code } from "react-notion-x/build/third-party/code";
import { Collection } from "react-notion-x/build/third-party/collection";
import { Equation } from "react-notion-x/build/third-party/equation";
import { Modal } from "react-notion-x/build/third-party/modal";
import Loading from "./Loading";

export default function NotionPageRenderer(props) {
  const {
    maxResult = 20,
    viewMoreComponent = undefined,
    disableHeader = true,
  } = { ...props };
  const [blocks, setBlocks] = useState(undefined);
  const { getAccessTokenSilently } = useAuth0();
  const loadData = async () => {
    const token = await getAccessTokenSilently();
    let resp = await BaseService.getNotion(token, props.pageId);

    // block the max result
    try {
      let level1 = Object.keys(resp.page.collection_query)[0];
      let level2 = Object.keys(resp.page.collection_query[level1])[0];

      let blockIds =
        resp.page.collection_query[level1][level2].collection_group_results
          .blockIds;
      blockIds = blockIds.slice(0, maxResult);

      resp.page.collection_query[level1][
        level2
      ].collection_group_results.blockIds = blockIds;
    } catch (e) {}

    setBlocks(resp);
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div>
      {!blocks && <Loading />}
      {blocks && (
        <NotionRenderer
          hideBlockId={true}
          disableHeader={disableHeader}
          recordMap={blocks.page}
          fullPage={true}
          darkMode={false}
          components={{
            Code,
            Collection,
            Equation,
            Modal,
          }}
        />
      )}
      {blocks && viewMoreComponent && viewMoreComponent()}
    </div>
  );
}
