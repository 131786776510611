import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext";
import { 
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  List,
  Modal,
  ModalBody,
  Row, 
  UncontrolledPopover,
  UncontrolledDropdown,
  PopoverBody,
} from "reactstrap";
import "./TeamMembers.scss";
import DataTable from "react-data-table-component";
import {
  ArrowDown,
  UserAdd,
  UserEdit,
} from "iconsax-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BsInfoCircle, BsPersonCheck } from 'react-icons/bs';
import Select from 'react-select'; 
import { Form, Field } from "react-final-form";
import FormStyles from "./TeamMembersFormStyles";
import Loading from "./Loading";
import mitt from "mitt";
import { Fade } from "react-awesome-reveal";

import MemberService from "../service/MemberService";
import { USER_ROLE, USER_ROLE_OPTIONS } from "../constants";
import e from "cors";

export default function TeamMembers(props) {

  const emitter = mitt();
  const maxInvestors = 5;

  const [context] = useContext(UserContext);
  const { lpEntityCode, lpCompanyName, data, newRequest, onClose } = { ...props };
  const { getAccessTokenSilently } = useAuth0();

  const { editEnabled = true } = props;
  const [modal, setModal] = useState(props.modal);
  const [done, setDone] = useState(false);
  const [action, setAction] = useState(props.action, undefined);
  const [selectedMember, setSelectedMember] = useState(undefined);
  const [newOrUpdatedMember, setNewOrUpdatedMember] = useState(undefined);
  const [loading, setLoading] = useState(false);
  
    const handleButtonClick = (e, row) => {
    e.preventDefault();
  };

  useEffect(() => {
    const handleStateChange = (newValue) => {
      // Handle state change
      // loadData();
    };
    emitter.on("stateChange", handleStateChange);

    return () => {
      emitter.off("stateChange", handleStateChange);
    };
  }, []);

  const roleOptions = USER_ROLE_OPTIONS;
  const multiSelectAccessRolesAdapter = ({input, ...props}) => 
  <Select
    defaultValue={props.initialValues || []}
    {...input} {...props}
    styles={{
      control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? "2px solid #ccc" : "1px solid #ccc",
        boxShadow: "none",
        "&:hover": {
        border: "2px solid #ccc",
        boxShadow: "none"
      }
      }),
    }}
  />
;
  const takeAction = (selectedMember, action) => {
    setSelectedMember(selectedMember);
    setAction(action);
    setModal(true);
  };
  const close = async () => {
    setDone(false);
    setModal(false);
    onClose();
    setNewOrUpdatedMember(undefined);
  };
  const confirmAction = async (
    token,
    lpEntityCode,
    action,
    values
  ) => {
    const data = {
      lpEntityCode: lpEntityCode,
      member: selectedMember,
      newOrUpdatedMember: values,
    };
    // and or update and email notification
    if (action === "add") {
      await MemberService.addMember(token, data);
    } else if (action === "update") {
      await MemberService.updateMember(token, data, selectedMember.id);
    } else if (action === "remove") {
      await MemberService.removeMemberAndSendNotification(token, data, selectedMember.id);
    }
    setLoading(false);              
  };

  const getActionIcon = (act) => {
    const color = "#3e7cb1";
    if (act === "add") {
      return (
        <UserAdd
          size="80"
          color={"white"}
          style={{
            background: color,
            borderRadius: "50%",

            padding: 13,
          }}
        />
      );
    } else if (act === "update") {
      return (
        <UserEdit
          size="80"
          color={"white"}
          style={{ background: color, borderRadius: "50%", padding: 13 }}
        />
      );
    }
  };

  const getNotificationMessage = (action) => {
    if (action === "add") {
      return (
        <div>
          <p>
            {newOrUpdatedMember.firstName} {newOrUpdatedMember.lastName} will receive an account activation email at {newOrUpdatedMember.email} with the following access role(s):
          </p> 
            <List className="text-left">
              {newOrUpdatedMember?.roles
                .slice(0, newOrUpdatedMember.roles.length)
                .map(({ tag }) => <li>{tag}</li>)
              }
            </List>
        </div>
      );
    } else if (action === "update") {
      return (
        <div>
         <div>
         The access role(s) for {selectedMember.firstName} {selectedMember.lastName} has been changed
         </div>
         <Row>
          <Col ></Col>
          <Col xs="9">
            <div className="text-left">
            from:
            </div>
          </Col>
          <Col></Col>
          </Row>
          <Row>
          <Col></Col>
          <Col xs="8">
            <List className="text-left">
                {selectedMember.roles.map(role => roleOptions.find(ro => ro.value === role).tag).map(tag => <li>{tag}</li>)}
            </List>
          </Col>
          <Col></Col>
          </Row>
          <Row>
          <Col></Col>
          <Col xs="9">
            <div className="text-left">
            to:
            </div>
          </Col>
          <Col></Col>
          </Row>
          <Row>
          <Col></Col>
          <Col xs="8">
          <List className="text-left">
                  {newOrUpdatedMember?.roles
                    .slice(0, newOrUpdatedMember.roles.length)
                    .map(({ tag }) => <li>{tag}</li>)
                  }
            </List>
          </Col>
          <Col></Col>
        </Row>
        </div>
       
      );
    } else if (action === "remove") { return (
      <div className="py-5">
        {selectedMember.firstName} {selectedMember.lastName} has been removed from {lpCompanyName}.
      </div>
      )
    }
  };

  
  const selectedMemberIsOnlyMemberWithUserRole = (userRole) => {
    let memberCount = 0;
    data?.forEach(member => {
      if (member.roles.includes(userRole)) {
        memberCount++;
      }
    });
    return memberCount === 1 && selectedMember.roles.includes(userRole);
  };

  const checkRemoveMemberWarnings = () => {
    let warnings = [];
    if (selectedMemberIsOnlyMemberWithUserRole(USER_ROLE.AUTH_SIGNATORY)) {
      warnings.push("There must be at least one authorized signatory in the membership.");
    }
    if (selectedMemberIsOnlyMemberWithUserRole(USER_ROLE.INVESTOR)) {
      warnings.push("There must be at least investor in the membership.");
    }
    if (selectedMemberIsOnlyMemberWithUserRole(USER_ROLE.MEMBERSHIP_MANAGER)) {
      warnings.push("There must be at least one membership manager in the membership.");
    }
    
    return warnings;
  };

  const getRemoveConfirmationMessage = () => {
    let warnings = checkRemoveMemberWarnings();
    if (warnings?.length > 0) {
      return (
        <div>
          <div className="pb-3">
            {selectedMember.firstName} {selectedMember.lastName} cannot be removed from {lpCompanyName}. For the following reason(s):
          </div>
          <Row>
          <Col ></Col>
          <Col xs="9">
            <div className="text-left">
            <ul>
            {warnings.map((warning, index) => (
              <li key={index}>{warning}</li>
            ))}
          </ul>
            </div>
          </Col>
          <Col></Col>
          </Row>
          {/* <ul>
            {warnings.map((warning, index) => (
              <li key={index}>{warning}</li>
            ))}
          </ul> */}
        </div>      
      );
    } else {
      return (
        <div>
          {selectedMember.firstName} {selectedMember.lastName} will be removed from {lpCompanyName}.
        </div>
      );
    }
  };

  const areRolesUpdated = (valuesRoles, initialRoles) => {
    return JSON.stringify(valuesRoles.sort((a, b) => a.value.localeCompare(b.value)), (key, value) => value) !==
      JSON.stringify(initialRoles.sort((a, b) => a.value.localeCompare(b.value)), (key, value) => value);
  }

  const customDataTableStyles = {
    rows: {
      style: {
        fontWeight: 200, // override the row height
      },
    }
  };
  

  const StatusCell = ({ value, id, info }) => (
    <div id={id}>
      <span>{value} </span>
      <BsInfoCircle/>
      <UncontrolledPopover placement="right" target={id} className="popover">
        <PopoverBody>
        <div className="popover-message">{info}</div>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );

  const onSubmit = async (values) => {
    const token = await getAccessTokenSilently();
    setLoading(true);
    setDone(true);
    setNewOrUpdatedMember(values);
    await confirmAction(
      token,
      lpEntityCode,
      action.replace(/ /g, "-"),
      values
    );
  };
  

  

  const columns = [
    {
      name: " ",
      selector: (row) => {
        const isCurrentUser = row.userId === context.id.toString();

        return (
          <Container className="pl-0" display="inline">
            <span>
            {isCurrentUser && (
              <span>
              <BsPersonCheck id="currentUser"/>
              <UncontrolledPopover placement="top" target="currentUser" className="popover-small">
                <PopoverBody>
                <div className="popover-message">Current user</div>
                </PopoverBody>
               </UncontrolledPopover>
              </span>
            )}
            </span>
          </Container>
        );
      },
      width: '30px',
    },
    {
      name: "Name",
      selector: (row) => (row.firstName || "") + "  " + (row.lastName || ""),
      sortable: true,
      expandableRows: true,
      grow: 3,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      expandableRows: true,
      grow: 4,
    },
    {
      name: "Status",
      sortable: true,
      grow: 2,
      selector: (row) =>
        row?.status?.toLowerCase() === "pending" ? (
          <StatusCell
            id="pendingInfo"
            value={row?.status?.toLowerCase()}
            className="popover"
            info={`The V0 team is reviewing the request and will send an email invitation to this user upon approval. The status will be updated to "invited" after the invitation has been sent.`}
          />
        ) : (
          row?.status?.toLowerCase()
        ),
    },
    // {
    //   name: "Last Active",
    //   selector: (row) => row.year,
    //   sortable: true,
    //   grow: 3,
    // },
    {
      name: <div id="accessRolesInfo">
      Access Roles &nbsp;
      <BsInfoCircle />
      <UncontrolledPopover
        placement="right"
        target="accessRolesInfo"
        className="popover"
      >
        <PopoverBody>
        <Row>
          <Col className="text-left pb-3">
            <div className="popover-message"><b>There are 3 types of access roles:</b></div>
          </Col>
        </Row>
        
        <Row lg="1" md="1" sm="1">
          {roleOptions
            .sort((a, b) => a.value.localeCompare(b.value))
            .map((op, i) => (
              <Col className="text-left" key={op.value}>
                <div className="popover-message pb-2" >{op.label}</div>
              </Col>
            ))}
        </Row>
        <Row>
          <Col className="text-left pt-3">
            <div className="popover-message"><i>*The maximum number of members that can have investor access is {maxInvestors}.</i></div>
          </Col>
        </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </div>,
      selector: (row) => (
        <Container className="badges-container">
          {roleOptions
            .sort((a, b) => a.value.localeCompare(b.value))
            .map((op, i) => (
              row.roles?.findIndex(r => r === op.value) !== -1 ? (
                // <Col key={op.value}>
                  <Badge className={`${op.value.toLowerCase()}-badge`}>{op.tag}</Badge>
                // </Col>
              ) : null
            ))
            .filter(Boolean)}
        </Container>
      ),
      grow: 3,
    },
  ];

  if (editEnabled) {
    columns.push({
      name: "",
      ignoreRowClick: true,
      selector: (member) => {
        return (
          <UncontrolledDropdown className="rounded z-3">
            <DropdownToggle color={""}>
              <FontAwesomeIcon
                color={"rgba(51, 51, 51, 0.8)"}
                icon={faEllipsisV}
                className="mr-2"
                size="xs"
              />
            </DropdownToggle>
            <DropdownMenu className="btn-light">
              <DropdownItem
                onClick={() => {
                  takeAction(member, "update");
                }}
              >
                Update Access
              </DropdownItem>

              <DropdownItem
                disabled={member.userId === context.id.toString()}
                onClick={() => {
                  takeAction(member, "remove");
                }}
              >
                Remove Member
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    });
  }

  return (
    <div>
      <Modal
        backdrop={"static"}
        centered={true}
        isOpen={modal}
        toggle={close}
        fullscreen={"sm"}
        fade={true}
        size={done?"md":"lg"}
        scrollable={true}
      >
    
        <ModalBody className={"bg-light text-center h-50 py-5"}>
        
        {done ? (
            <div className="text-center">
              <Fade>
                <div className={"align-items-center justify-content-center py-3"}>
                  {getActionIcon(action)}
                </div>
              </Fade>
              <div className="py-1">
                <h4>Your request has been submitted</h4>
              <span className={"text-muted text-light"}>
                {getNotificationMessage(action)}
              </span>
              </div>
              <Fade cascade className="pt-3">
                <Button
                  className="mx-0 my-1 base-button primary-button"
                  onClick={close}
                >
                  Close
                </Button>
              </Fade>
            </div>
          ) : null}

        {!done && action!=="remove" && (
            <div className="px-1">
              {(action === "update") && (<div><h4 className="text-left">Update Member Access Roles</h4></div>)}
              {(action === "add") && (<div><h4 className="text-left">Add New Member Request</h4></div>)}   
              <FormStyles>
                <Form
                  onSubmit={(values) => onSubmit(values)}
                  initialValues={{
                    firstName: action === "update" ? selectedMember.firstName : "",
                    lastName: action === "update" ? selectedMember.lastName : "",
                    email: action === "update" ? selectedMember.email : "",
                    roles: action === "update" ? roleOptions.filter(role => selectedMember?.roles?.includes(role.value)): [],
                  }}
                  validate={(values) => {
                    const errors = {};
                    
                      if (!values.email) {
                        errors.email = "";
                      }
                      const emailExists = data.find(member => member.email === values.email);
                      if (emailExists && action === "add") {
                        errors.email = "Member with the same email already exists";
                      }
                      if (!values.firstName) {
                        errors.firstName = "Required";
                      }
                      if (!values.lastName) {
                        errors.lastName = "Required";
                      }

                      // Verify Roles
                      let initialRoles = action === "update" ? roleOptions.filter(role => selectedMember?.roles?.includes(role.value)): [];
                      let noOfInvestors =(data?.filter(member => member.roles?.includes(USER_ROLE.INVESTOR)).length || 0);

                      if (!values.roles || values.roles.length === 0) {
                        errors.roles = "";
                      }
                      if (values.roles.find(role => role.value===USER_ROLE.AUTH_SIGNATORY)
                      && !values.roles.find(role => role.value===USER_ROLE.INVESTOR)) {
                        errors.roles = `<b>Authorized Signatory</b> requires an <b>Investor</b> role`;
                       }
                      if (values.roles.find(role => role.value===USER_ROLE.INVESTOR)) {
                        if((!initialRoles.find(role => role.value===USER_ROLE.INVESTOR) && noOfInvestors >= maxInvestors ) ||
                        (initialRoles.find(role => role.value===USER_ROLE.INVESTOR && noOfInvestors > maxInvestors ))) {
                          errors.roles = `The maximum number of members with <b>Investor</b> role (${maxInvestors}) has been reached.`;;
                        }
                      }
                      if (action === "update" &&!areRolesUpdated(values.roles, initialRoles)) {
                        errors.roles = "No changes made to <b>Access Roles</b>";
                      }
                      if (initialRoles.find(role => role.value===USER_ROLE.AUTH_SIGNATORY)) {
                        const authSignatories = data?.filter(member => member.roles?.includes(USER_ROLE.AUTH_SIGNATORY));
                        if (authSignatories.length === 1 && !values.roles.find(role => role.value===USER_ROLE.AUTH_SIGNATORY)) {
                          errors.roles = "There must be at least one <b>Authorized Signatory</b>";
                        }
                      }
                      if (initialRoles.find(role => role.value===USER_ROLE.MEMBERSHIP_MANAGER)) {
                        const authSignatories = data?.filter(member => member.roles?.includes(USER_ROLE.MEMBERSHIP_MANAGER));
                        if (authSignatories.length === 1 && !values.roles.find(role => role.value===USER_ROLE.MEMBERSHIP_MANAGER)) {
                          errors.roles = "There must be at least one <b>Membership Manager</b>";
                        }
                      }
                
                    return errors;
                  }}
                  render={({
                    errors,
                    values,
                    handleSubmit,
                    submitting,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {loading && <Loading />}
                      {(action === "add" || action === "update") && (
                        <>
                        
                          <Row sm="2" xs="1">
                            <Col className="form-fields">
                              <Label>First Name</Label>
                              <Field
                              name="firstName"
                              component="input"  
                              readOnly={action === "update"}                   
                              placeholder={(action === "add" ? "First" : "")}

                            />                         
                            </Col>
                            <Col className="form-fields">
                              <Label>Last Name</Label>
                              <Field
                              name="lastName"
                              component="input"  
                              readOnly={action === "update"}                      
                              placeholder={(action === "add" ? "Last" : "")}
                              />   
                            </Col>
                          </Row>
                          <Row>
                          <div className="form-fields">
                              <Label>Email</Label>
                              <Field
                              name="email"
                              type="email"
                              component="input"     
                              readOnly={action === "update"}                    
                              placeholder={(action === "add" ? "eg. email@company.com" : "")}
                            /> 
                          </div>
                          <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.email}
                              </Fade>
                            </div>
                          </Row>
                          <div className="form-fields">
                            <Label>Access Roles</Label>
                            <Field
                              name="roles"
                              component={multiSelectAccessRolesAdapter}
                              isMulti
                              options={roleOptions}
                              className="basic-multi-select text-left team-members mt-0"
                              classNamePrefix="select"
                            />
                          </div>
                          <div className="text-left notes mx-1">
                              <Fade>
                                {errors.roles && <div dangerouslySetInnerHTML={{__html: errors.roles}} />}
                              </Fade>
                            </div>
                        </>
                      )}

                      <Button
                        onClick={close}
                        className="mx-2 base-button ternary-button"
                      >
                        Cancel
                      </Button>

                      {!done && (
                        <Button
                          disabled={!valid || loading  || submitting}
                          type="submit"
                          className={"base-button mx-2 primary-button"}
                        >
                          {action === "add" ? "Request" : "Update"}
                        </Button>
                      )}
                    </form>
                  )}
                ></Form>
              </FormStyles>
            </div>
          )}
        {!done && action==="remove" && (
          <div className="px-1">
          <div><h1 className="text-left">Remove Member</h1></div>
          <span className={"text-muted text-light"}>
                <div className="py-5">
                  {getRemoveConfirmationMessage()}
                </div>
              </span>
          <FormStyles>
            <Form
              onSubmit={(values) => onSubmit(values)}
              render={({
                errors,
                values,
                handleSubmit,
                submitting,
                valid,
              }) => (
                <form onSubmit={handleSubmit}>
                  {loading && <Loading />}
                  
                  <Button
                    onClick={close}
                    className="mx-2 base-button ternary-button"
                  >
                    Cancel
                  </Button>

                  {checkRemoveMemberWarnings().length < 1 && (
                  <Button
                      disabled={!valid || loading  || submitting}
                      type="submit"
                      className={"base-button mx-2 primary-button"}
                    >
                      Confirm
                  </Button>
                  )}
                </form>
              )}
            ></Form>
          </FormStyles>
        </div>
        )}
        </ModalBody>
      </Modal>
     {(!newRequest) && (
      <Container className="pb-3 team-members">
        <DataTable
          className="pb-3 team-members"
          defaultSortFieldId={2}
          columns={columns}
          // data={((members && members.length>0)?members:data)}
          data={data}
          highlightOnHover
          sortIcon={<ArrowDown className="mx-3"/>}
          emptyComponent={<div></div>}
          customStyles={customDataTableStyles}
        />
      </Container>
     )
  }

   </div>
    
  );
}
