import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import VCFundService from "../service/VCFundService";
import { Fade, Slide } from "react-awesome-reveal";

import useScreenType from "react-screentype-hook";

import "./VCFundCarousel.scss";
import VCFundCard from "./VCFundCard";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import VCFundListing from "./VCFundListing";

export default function VCFundCarousel(props) {
  const screenType = useScreenType();

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const {
    title = "",
    description = "",
    publicView = true,
    tiers = [],
    exceptionalFundCategory = undefined,
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [exceptionalFundData, setExceptionalFundData] = useState({});

  let defaultOffset = 0;
  let defaultQuery = "";

  const [offset, setOffset] = useState(defaultOffset);
  const [query, setQuery] = useState(defaultQuery);

  let limit = 10;

  const loadData = async () => {
    setLoading(true);
    setData([]);
    try {
      const token = await getAccessTokenSilently();

      let resp = await VCFundService.search(token, {
        limit: limit,
        offset: offset,
        query: query,
        tiers: tiers,
      });

      if (resp.error){
        return;
      }

      if (exceptionalFundCategory) {
        let rows = resp.rows.filter((d) => {
          return d.fundCategory?.name !== exceptionalFundCategory;
        });

        let exceptionalFunds = resp.rows.filter((d) => {
          return d.fundCategory?.name === exceptionalFundCategory;
        });

        if (exceptionalFunds.length > 0) {
          setExceptionalFundData({
            funds: exceptionalFunds,
            fundCategory: exceptionalFunds[0].fundCategory,
          });
        }
        setData(rows);
        setCount(resp.total - (resp.rows.length - rows.length));
      } else {
        setData(resp.rows);
        setCount(resp.total);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [screenType.isMobile, screenType.isDesktop, screenType.isTablet]);

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType, slidesToShow, totalItems },
    } = rest;

    if (currentSlide + slidesToShow >= totalItems) {
      return (
        <div className="arrow-right disabled" onClick={() => onClick()}></div>
      );
    } else {
      return <div className="arrow-right" onClick={() => onClick()}></div>;
    }
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType, slidesToShow, totalItems },
    } = rest;

    if (currentSlide === 0) {
      return (
        <div className="arrow-left disabled" onClick={() => onClick()}></div>
      );
    } else {
      return <div className="arrow-left" onClick={() => onClick()}></div>;
    }
  };

  // // if less than 3 of the last tier, show the last tier
  // if (
  //   tiers &&
  //   tiers.length > 0 &&
  //   data.length &&
  //   data.length < 3 &&
  //   data.length > 0
  // ) {
  //   return (
  //     <Row className={"py-3 my-3"}>
  //       <Col>
  //         <h4 className="text-center fund-category-title">Additional Funds</h4>
  //         {tiers.map((tier) => (
  //           <VCFundListing
  //             title={""}
  //             tier={tier}
  //             publicView={true}
  //             hideCategoryTitle={true}
  //           />
  //         ))}
  //       </Col>
  //     </Row>
  //   );
  // }
  return (
    <Container fluid className="VCFundCarousel" key={data.length}>
      {exceptionalFundData?.funds?.length > 0 && (
        <Row className={""}>
          <Row className={"py-3 my-3"}>
            <Col>
              <h4 className="text-center my-3 fund-category-title">
                {exceptionalFundData?.fundCategory?.title}
              </h4>
              <div
                className={"col col-md-10 mx-auto  fund-category-description"}
              >
                {exceptionalFundData?.fundCategory?.description}
              </div>
            </Col>
          </Row>
        </Row>
      )}

      <div
        className={"page-width"}
        style={{
          justifyContent:
            exceptionalFundData?.funds?.length % 2 === 0
              ? "center"
              : "space-around",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          //  alignContent: "center",
        }}
      >
        <Fade cascade triggerOnce>
          {exceptionalFundData?.funds?.map((d, index) => {
            return (
              <div className="px-4" key={index}>
                <VCFundCard
                  key={index}
                  widthMode={true}
                  data={d}
                  showQuote={true}
                  title={""}
                />
              </div>
            );
          })}
        </Fade>
      </div>

      <Row className={"py-3 my-3"} id="additional-funds">
        <Fade triggerOnce delay={500} cascade>
          {count > 0 ? (
            <Col>
              <div>
                <h4 className="text-center my-3 fund-category-title">
                  {title}
                </h4>
              </div>
              <div
                className={"col col-md-10 mx-auto  fund-category-description"}
              >
                {description}
              </div>
            </Col>
          ) : null}
        </Fade>
      </Row>

      <Carousel
        arrows
        centerMode={false}
        className="p-0 m-0"
        containerClass="container"
        rtl={false}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        draggable
        focusOnSelect={false}
        keyBoardControl
        //        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={true}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: Math.min(data.length, 3),
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        shouldResetAutoplay={false}
        showDots
        sliderClass=""
        slidesToSlide={
          screenType.isMobile || screenType.isTablet
            ? 1
            : Math.min(data.length, 3)
        }
        swipeable
      >
        {data.map((d, index) => {
          return (
            <Fade cascade delay={(index + 1) * 200} key={index}>
              <VCFundCard key={index} data={d} showQuote={false} title={""} />
            </Fade>
          );
        })}
      </Carousel>

      {count > limit && (
        <Fade triggerOnce delay={1000}>
          <Row className={"mx-auto"}>
            <Col>
              <Container className="px-5 mx-auto page-width ">
                <Link to={"/fund-gallery/fund-library"}>
                  See all funds <ArrowRight color="#333333" />
                </Link>
              </Container>
            </Col>
          </Row>
        </Fade>
      )}
    </Container>
  );
}
