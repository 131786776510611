import React, {useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../UserContext";
import { 
  Button,
  Col,
  Fade,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import OnboardingSideMenu from "./OnboardingSideMenu";
import OnboardingIntro from "./OnboardingIntro";
import OnboardingSubDoc from "./OnboardingSubDoc";
import OnboardingKYC from "./OnboardingKYC";
import InvestingEntityService from "../service/InvestingEntityService";
import Loading from "./Loading";
import OnboardingEmpty from "./OnboardingEmpty";
import FormStyles from "./TeamMembersFormStyles";
import Select from 'react-select'; 
import { Field, Form, useField, useForm } from "react-final-form";
import { ArchiveAdd, ArchiveTick } from "iconsax-react";

import { 
  INVESTING_ENTITY_DOC_TYPE,
  INVESTING_ENTITY_DOC_TYPE_MAP,
  INVESTING_ENTITY_TYPE_OPTIONS, 
  DOCUMENT_STATUS 
} from "../constants";
import { getUSCountry, getFATFCountries, getCountryByCode } from "../utils/common";

const investingEntityTypeOptions = INVESTING_ENTITY_TYPE_OPTIONS;

export const Onboarding = (props) => {
  const { lpEntityCode, lpLegalName } = { ...props };

  const { getAccessTokenSilently } = useAuth0();
  const [componentToShow, setComponentToShow] = useState('intro');
  const [selectedInvestingEntity, setSelectedInvestingEntity] = useState(null);
  const [investingEntities, setInvestingEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [action, setAction] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [done, setDone] = useState(false);
  const [newOrUpdatedEntity, setNewOrUpdatedEntity] = useState(undefined);
  const [countries, setCountries] = useState([]);
  const [usCountry, setUSCountry] = useState(undefined);

  const goToStep = async (selectedLink, selectedItemId, investingEntity) => {
    setComponentToShow(selectedLink);
    setSelectedItemId(selectedItemId);
    //reloadInvestingEntity
    const token = await getAccessTokenSilently();
    const latestInvestingEntity = await InvestingEntityService.getById(token, investingEntity?.id);
    setSelectedInvestingEntity(latestInvestingEntity);
  }

  const loadData = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      if(lpEntityCode) {
        const investingEntities = await InvestingEntityService.getByLPEntityCode(token, lpEntityCode);
        setInvestingEntities(investingEntities);
        setSelectedInvestingEntity(investingEntities[0]);
      }
      setCountries(getFATFCountries());
      setUSCountry(getUSCountry());
    } catch (e) {

    } finally {
      setLoading(false);
    }

    
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  const takeAction = (action) => {
    setAction(action);
    setModal(true);
  };
  const close = async () => {
    setDone(false);
    setModal(false);
    loadData();  
    setLoading(false);
  };

  const confirmAction = async (
    action,
    values
  ) => {
    
    try{
      const token = await getAccessTokenSilently();
      if(action === "add"){
        const data = {
          name: values?.name,
          entityType: values?.entityType?.value,
          lpEntityCode: lpEntityCode,
          registeredCountry: values?.registeredCountry?.value,
        };
          await InvestingEntityService.addInvestingEntity(token, data);
      }
    } catch(e) {
      console.error(e);
    } finally {
    }           
  };

  const onSubmit = async (values) => {
    setLoading(true);
    setDone(true);
    setNewOrUpdatedEntity(values);
    await confirmAction(
      action.replace(/ /g, "-"),
      values
    );
  };

  const getActionIcon = (act) => {
    const color = "#3e7cb1";
    if (act === "add") {
      return (
        <ArchiveAdd
          size="80"
          color={"white"}
          style={{
            background: color,
            borderRadius: "50%",

            padding: 20,
          }}
        />
      );
    } else if (act === "update") {
      return (
        <ArchiveTick
          size="80"
          color={"white"}
          style={{ background: color, borderRadius: "50%", padding: 13 }}
        />
      );
    }
  };

  const getNotificationMessage = (action) => {
    if (action === "add") {
      return (
        <div>
          <p>
            {newOrUpdatedEntity.name} is added to {lpLegalName}.
          </p> 
        </div>
      );
    }
  };

  const selectAdapter = ({input, ...props}) => 
    
    <Select
      defaultValue={props.values ||props.initialValues || ""}
      {...input}
      {...props}
      styles={{
        control: (provided, state) => ({
          ...provided,
          border: state.isFocused ? "2px solid #ccc" : "1px solid #ccc",
          boxShadow: "none",
          "&:hover": {
          border: "2px solid #ccc",
          boxShadow: "none"
        }
        }),
      }}
    />

    const Interactivefield = ({ name, options, dependsOn, ...props }) => {
      const form = useForm();
      const { input, meta } = useField(name);
      const dependentField = useField(dependsOn)
  
      React.useEffect(() => {
        if (dependentField.input.value) {
          form.change(name, usCountry);
        }
      }, [dependentField.input.value])
  
      return (
        <Select
        defaultValue={props.values ||props.initialValues || ""}
        options={options}
        {...input}
        {...props}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? "2px solid #ccc" : "1px solid #ccc",
            boxShadow: "none",
            "&:hover": {
            border: "2px solid #ccc",
            boxShadow: "none"
          }
          }),
        }}
      />
  
      )
    }  
  return (
    <div>

    <Modal
        backdrop={"static"}
        centered={true}
        isOpen={modal}
        toggle={close}
        fullscreen={"sm"}
        fade={true}
        size={done?"md":"lg"}
        scrollable={true}
      >
    
        <ModalBody className={"bg-light text-center h-50 py-5"}>
        
        {done ? (
            <div className="text-center">
              <Fade>
                <div className={"align-items-center justify-content-center py-3"}>
                  {getActionIcon(action)}
                </div>
              </Fade>
              <div className="py-1">
                <h4>Done!</h4>
              <span className={"text-muted text-light"}>
                {getNotificationMessage(action)}
              </span>
              </div>
              <Fade cascade className="pt-3">
                <Button
                  className="mx-2 base-button ternary-button"
                  onClick={close}
                >
                  Close
                </Button>
              </Fade>
            </div>
          ) : null}

        {!done && (
            <div className="px-1">
              {/* {(action === "update") && (<div><h1 className="text-left">Update Investing Entity</h1></div>)} */}
              {(action === "add") && (<div><h4 className="text-left">Add New Entity</h4></div>)}   
              <FormStyles>
                <Form
                  onSubmit={(values) => onSubmit(values)}
                  initialValues={{
                    name: action === "update" ? selectedInvestingEntity?.name : "",
                    entityType: action === "update" ? INVESTING_ENTITY_TYPE_OPTIONS.find(type => type?.value === selectedInvestingEntity?.entityType) : "",
                    registeredCountry: action ==="update" ? 
                      (selectedInvestingEntity?.entityType === "US Legal Entity" ? countries.find(country => country?.value === "US")
                      : countries.find(country => country?.value === selectedInvestingEntity?.registeredCountry))
                      : "",
                  }}
                  validate={(values) => {
                    const errors = {};
                      let isChanged = false;
                      if (values?.name !== selectedInvestingEntity?.name) {
                        isChanged = true;
                      }
                      if (values?.entityType?.value !== selectedInvestingEntity?.entityType) {
                        isChanged = true;
                      }
                      if (values?.registeredCountry?.value !== selectedInvestingEntity?.registeredCountry) {
                        isChanged = true;
                      }
                      if (values?.lpEntityCode?.value !== selectedInvestingEntity?.lpEntityCode) {
                        isChanged = true;
                      }

                      if (action==="update" && !isChanged) {
                        errors.overall = "No changes made";
                      }

                    return errors;
                  }}
                  render={({
                    errors,
                    handleSubmit,
                    submitting,
                    valid,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <>
                         <Row>
                          <div className="form-fields">
                              <Label>Entity Legal Name</Label>
                              <Field
                              name="name"
                              component="input"  
                              // readOnly={action === "update"}                   
                              placeholder={(action === "add" ? "Entity Legal Name" : "")}
                            /> 
                            </div> 
                            <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.name}
                              </Fade>
                          </div>                       
                          </Row>  
                          <Row sm="2" xs="1">
                            <Col className="form-fields">
                              <Label>Entity Type</Label>
                              <Field
                              name="entityType"
                              component={selectAdapter} 
                              options={investingEntityTypeOptions} 
                              // value={selectedEntityType}
                              className="basic-multi-select text-left team-members mt-0"
                              classNamePrefix="select"
                            /> 
                            
                              <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.entityType}
                              </Fade>
                              </div>
                            </Col>
                            <Col className="form-fields">
                              <Label>Registered Country</Label>
                                                       
                              <Interactivefield
                              name="registeredCountry"
                              component={selectAdapter} 
                              options={values.entityType?.value !== "US Legal Entity" ? countries:[usCountry]} 
                              className="basic-multi-select text-left team-members mt-0"
                              classNamePrefix="select"
                              dependsOn={"entityType"}
                            /> 
                            
                            <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.registeredCountry}
                              </Fade>
                            </div>
                            </Col>
                          </Row>
                        </>
                      
                      <div className="text-left error-message mx-3">
                              <Fade>
                                {errors.overall}
                              </Fade>
                          </div>
                      <Button
                        onClick={close}
                        className="mx-2 base-button ternary-button"
                      >
                        Cancel
                      </Button>

                      {!done && (
                        <Button
                          disabled={!valid || loading  || submitting}
                          type="submit"
                          className={"base-button mx-2 primary-button"}
                        >
                          {action === "add" ? "Add" : "Update"}
                        </Button>
                      )}
                    </form>
                  )}
                >
                  TestABC
                </Form>
              </FormStyles>
            </div>
          )}
          </ModalBody>
        </Modal>
     {loading && <Loading />}
     {/* {!loading && investingEntities?.length === 0 && (
      <div>
        <OnboardingEmpty/>
      </div>
      )} */}
     {!loading && (
    <div>
      <Row>
      { investingEntities.length === 0 && (
          <Col md="3">
          <OnboardingEmpty
                    investingEntities={investingEntities}
                    goToStep={goToStep}
                    selectedItemId={selectedItemId}
                    takeAction={takeAction}
                    // data={data}
                    // onClose={closeModal}
                  />
          </Col>
        )}
        { investingEntities.length > 0 && (
          <Col md="3">
          <OnboardingSideMenu
                    investingEntities={investingEntities}
                    goToStep={goToStep}
                    selectedItemId={selectedItemId}
                    // data={data}
                    // onClose={closeModal}
                  />
          </Col>
        )}
        { componentToShow === 'intro' && (
          <Col>
            <OnboardingIntro
              investingEntity={selectedInvestingEntity}
              goToStep={goToStep}
              selectedItemId={selectedItemId}
              // data={data}
              // onClose={closeModal}
            />
          </Col>
        )}
        {componentToShow === 'subdoc' && (
        <Col> 
        <OnboardingSubDoc
                  investingEntity={selectedInvestingEntity}
                  selectedItemId={selectedItemId}
                  goToStep={goToStep}
                  // data={data}
                  // onClose={closeModal}
                />
        </Col>
        )}
        {componentToShow === 'kyc' && (
        <Col> 
        <OnboardingKYC
                  lpEntityCode={lpEntityCode}
                  lpLegalName={lpLegalName}
                  // data={data}
                  // onClose={closeModal}
                />
        </Col>
        )}
      </Row>
    </div>
    )}
    </div>
    )
};

export default Onboarding;
