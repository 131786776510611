import React, { useContext, useEffect } from "react";
import { Container } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import FundSelection from "../components/FundSelection";
import FundAnnouncement from "../components/FundAnnouncement";
import { checkIsAuthorized } from "../utils/authorization";
import { ACCESS } from "../constants";

export const WelcomeComponent = (props) => {
  const [context] = useContext(UserContext);
  const navigate = useNavigate();
  const stay = props.stay;

  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const isPending = () => {
    if (!isAuthenticated) {
      return true;
    }
    if (context?.status === "PENDING") {
      return true;
    }

    return false;
  };

  const isActived = () => {
    if (!isAuthenticated) {
      return true;
    }
    if (context?.status === "ACTIVE") {
      return true;
    }

    return false;
  };

  const loadData = async () => {
    let returnTo = (localStorage.getItem("returnTo") || "").replace("/#", "");

    if (context?.type === "VT" && !stay) {
      navigate("/admin");
      return;
    } else if (!stay && context?.status === "ACTIVE") {
      if (returnTo) {
        navigate(returnTo);
        setTimeout(() => localStorage.setItem("returnTo", "", 1000));
        return;
      }
      else if (checkIsAuthorized(context, [ACCESS.REVIEW_FUNDS])) {
        navigate("/fund-gallery");
        return;
      } else if (checkIsAuthorized(context, [ACCESS.MANAGE_MEMBERSHIP])) {
        navigate("/manage-team");
        return; 
      } else {
        navigate("/profile");
        return;
      }
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/welcome",
    });

    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div className={"mb-5"}>
      {isActived() ? (
        <div className={"justify-content-center w-100 h-100"}>
          {/* <Hero2
            image={2}
            title={
              "Our singular focus is to distinguish the highest performing venture capital funds."
            }
            subtitle={
              "You are one of the few families selected to access this platform"
            }
          /> */}
          <Container>
            {/* <WorldStats /> */}
            {/* <Funnel /> */}
            <FundSelection />
          </Container>
          <FundAnnouncement />
        </div>
      ) : null}

      {/* <Link
        to={`/dashboard`}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "40px",
          zIndex: 100,
        }}
      >
        <Fade delay={3e3} cascade damping={1e-1}>
          <Button className={"primary-button base-button "}>
            Go to Dashboard <ArrowRight size="32" color="#FFF" />
          </Button>
        </Fade>
      </Link> */}
    </div>
  );
};

export default withAuthenticationRequired(WelcomeComponent, {
  onRedirecting: () => <Loading />,
});
