import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useNavigate,
  NavLink as RouterNavLink,
  useLocation,
} from "react-router-dom";

import {
  Button,
  CloseButton,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";

import logo from "../assets/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../UserContext";
import { CloseSquare, HambergerMenu } from "iconsax-react";
import LPTeamSelector from "./LPTeamSelector";
import DashboardService from "../service/DashboardService";
import BaseService from "../service/BaseService";
import { Fade } from "react-awesome-reveal";
import Loading from "./Loading";
import { checkIsAuthorized } from "../utils/authorization";
import { ACCESS } from "../constants";

const NavBar = () => {
  let navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const [newsPageId, setNewsPageId] = useState(null);

  const [lpContext, setLpContext] = useState({});

  const [context] = useContext(UserContext);

  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  const isProperlyAuthenticated = () => {
    return isAuthenticated && context && context.status === "ACTIVE";
  };

  const isPending = () => {
    return (
      (isAuthenticated && context && context?.status === "PENDING") ||
      context?.status === "PENDING-PAYMENT"
    );
  };
  const isAdminAndAuthenticated = () => {
    return (
      isAuthenticated &&
      context &&
      context.status !== "PENDING" &&
      context.type === "VT"
    );
  };
  
  const isAuthorized = (accessNames) => {
    return(checkIsAuthorized(context, accessNames));
  };

  
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const logoComponent = <img src={logo} width={50} alt="V0 logo" />;
  const location = useLocation();

  const isInviting = () => {
    return (
      location.pathname.indexOf("invitation") !== -1 ||
      location.pathname.indexOf("signup") !== -1
    );
  };

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();

      let conf = await DashboardService.getConfiguration(token);

      let newsId = conf?.find(
        (e) => e.name.toLowerCase() === "dashboard-news-page-id"
      )?.meta?.value;
      setNewsPageId(newsId);

      let resp2 = (await BaseService.getCurrentLPProfile(token)) || {};
      setLpContext(resp2);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const canViewNews = () => {
    if (!newsPageId) {
      return false;
    } else if (context.type === "VT") {
      return true;
    } else if (context.type === "LP") {
      if (!lpContext.meta) {
        return false;
      } else if (lpContext.meta?.type === undefined) {
        return true;
      } else if (lpContext.meta?.type === "FO") {
        return true;
      } else if (lpContext?.meta?.type === "ALP") {
        return false;
      }
    } else {
      return true;
    }
  };

  const canViewHowToInvest = () => {
    if (context.type === "VT") {
      return true;
    } else if (context.type === "LP") {
      if (!lpContext.meta) {
        return false;
      } else if (lpContext.meta?.type === undefined) {
        return true;
      }
      if (lpContext?.meta?.type === "FO") {
        return true;
      }
      if (lpContext?.meta?.type === "ALP") {
        return false;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={`nav-container`}>
      <Navbar
        className={`navbar ${
          !isProperlyAuthenticated() ? "navbar-expand" : "navbar-expand-md"
        } fixed-top`}
      >
        <Nav className="mr-auto flex-row" navbar>
          <NavItem className="ml-3 d-block">
            {isProperlyAuthenticated()  && isAuthorized([ACCESS.REVIEW_FUNDS]) ? (
              <NavLink
                className={"brand"}
                tag={RouterNavLink}
                to="/dashboard"
                exact="true"
              >
                {logoComponent}
              </NavLink>
            ) : (
              <NavLink
                className={"brand"}
                tag={RouterNavLink}
                to="/"
                exact="true"
              >
                {logoComponent}
              </NavLink>
            )}
          </NavItem>

          <LPTeamSelector className={"d-block"} />
        </Nav>
        <NavbarToggler onClick={toggle}>
          {isOpen ? (
            <CloseButton color={"#000"} />
          ) : (
            <HambergerMenu size={32} color={"#000"} />
          )}
        </NavbarToggler>

        <Collapse
          isOpen={isOpen}
          navbar
          className={`${isOpen ? "shadow-sm" : ""}`}
        >
          <Nav
            className={`mr-auto mr-1 `}
            navbar
            style={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            {isAdminAndAuthenticated() && isAuthorized([ACCESS.ADMIN]) && (
              <NavItem>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/admin"
                  exact="true"
                >
                  Admin
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated() && isAuthorized([ACCESS.REVIEW_FUNDS]) && (
              <NavItem className={"ml-2"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/dashboard"
                  exact="true"
                >
                  Dashboard
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated()  && isAuthorized([ACCESS.REVIEW_FUNDS]) && canViewHowToInvest() && (
              <NavItem className={"ml-2"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/fund-gallery"
                  exact="true"
                >
                  Fund Gallery
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated()  && isAuthorized([ACCESS.REVIEW_FUNDS]) && !canViewHowToInvest() && (
              <NavItem className={"ml-2"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/fund-gallery"
                  exact="true"
                >
                  Fund Gallery
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated()  && isAuthorized([ACCESS.REVIEW_FUNDS]) && canViewHowToInvest() && (
              <NavItem className={"ml-2"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/how-to-invest"
                  exact="true"
                >
                  How to Invest
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated()  && isAuthorized([ACCESS.REVIEW_FUNDS]) && canViewNews() && (
              <NavItem className={"ml-2"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/portfolio-updates"
                  exact="true"
                >
                  Portfolio Updates
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated() && isAuthorized([ACCESS.PROFILE]) && (
              <NavItem className={"ml-2 d-block d-md-none"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/profile"
                  exact="true"
                >
                  Profile
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated() && isAuthorized([ACCESS.PROFILE]) && (
              <NavItem className={"ml-2 d-block d-md-none"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/privacy-policy"
                  exact="true"
                >
                  Privacy Policy
                </NavLink>
              </NavItem>
            )}

            {isProperlyAuthenticated() && isAuthorized([ACCESS.PROFILE]) && (
              <NavItem className={"ml-2 d-block d-md-none"}>
                <NavLink
                  className="item"
                  onClick={close}
                  tag={RouterNavLink}
                  to="/terms-of-service"
                  exact="true"
                >
                  Terms of Service
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <Nav className="d-none d-md-block  mr-1" navbar>
            {isPending() && (
              <NavItem className={"ml-2"}>
                <NavLink
                  onClick={close}
                  tag={RouterNavLink}
                  to="/invitation"
                  exact="true"
                >
                  PENDING
                </NavLink>
              </NavItem>
            )}
            {!isProperlyAuthenticated() && !isPending() && !isInviting() && (
              <NavItem>
                <Button
                  id="qsLoginBtn"
                  color="primary"
                  className="btn-margin mr-1 primary-button base-button"
                  onClick={() => loginWithRedirect()}
                >
                  Log in
                </Button>
              </NavItem>
            )}
            {isProperlyAuthenticated()  && isAuthorized([ACCESS.PROFILE]) && (
              <UncontrolledDropdown nav className={"mr-3 ml-1"} inNavbar>
                <DropdownToggle nav caret id="profileDropDown">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile rounded-circle"
                    width="32"
                  />
                </DropdownToggle>

                <DropdownMenu className={"dropdown-menu"}>
                  <DropdownItem onClick={() => navigate("profile")}>
                    <Link
                      onClick={close}
                      to="/profile"
                      exact="true"
                      style={{ flex: 1 }}
                    >
                      Profile
                    </Link>
                  </DropdownItem>

                  <DropdownItem onClick={() => navigate("privacy-policy")}>
                    <Link
                      onClick={close}
                      to="/privacy-policy"
                      exact="true"
                      style={{ flex: 1 }}
                    >
                      Privacy Policy
                    </Link>
                  </DropdownItem>

                  <DropdownItem onClick={() => navigate("terms-of-service")}>
                    <Link
                      onClick={close}
                      to="/pterms-of-service"
                      exact="true"
                      style={{ flex: 1 }}
                    >
                      Terms of Service
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav>
          {!isProperlyAuthenticated() && (
            <Nav className="d-md-none mr-3" navbar>
              <NavItem>
                {!isInviting() && (
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    className="ml-2 mr-2  border-0 primary-button base-button"
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                )}
                {/* <NavLink onClick={close}>
                    <Link to={"/about"}>About</Link>
                  </NavLink> */}

                {/* <NavLink onClick={close}>
                    <Link to={"/signup"}>Sign up</Link>
                  </NavLink> */}
                {/* 
                  <NavLink onClick={close}>
                    <Link to={"/faq"}>FAQ</Link>
                  </NavLink> */}
              </NavItem>
            </Nav>
          )}
          {isProperlyAuthenticated() && (
            <Nav className="d-md-none justify-content-between" navbar>
              {/*<NavItem>*/}
              {/*  <span className="user-info">*/}
              {/*    <img*/}
              {/*      src={user.picture}*/}
              {/*      alt="Profile"*/}
              {/*      className="nav-user-profile d-inline-block rounded-circle mr-3"*/}
              {/*      width="50"*/}
              {/*    />*/}
              {/*    <h6 className="d-inline-block">{user.name}</h6>*/}
              {/*  </span>*/}
              {/*</NavItem>*/}

              <NavItem className={"ml-3"}>
                <NavLink
                  className="item"
                  to="#"
                  id="qsLogoutBtn"
                  onClick={() => logoutWithRedirect()}
                >
                  Log out
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
