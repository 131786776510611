import BaseService from "./BaseService";


class LPEntityUserService {

  static async getLPEntityUserByCode(token, LPEntityCode) {

    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/lpentityuser/${LPEntityCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include'
    });

    const responseData = await response.json();
    return responseData
  }


  static async addLPEntityUser(token, data) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/lpentityuser`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

  //deprecated - use MemberService.removeMember instead
  static async deleteLPUserEntity(token, id) {
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/lpentityuser/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'delete',
      credentials: 'include'
    });
    return response.statusText
  }

}

export default LPEntityUserService;
