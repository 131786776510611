import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AdminInfo from "../components/AdminInfo";
import ReactGA from "react-ga4";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import VCFundTable from "../components/VCFundTable";
import UserTable from "../components/UserTable";
import LPTable from "../components/LPTable";
import ConfigurationTable from "../components/ConfigurationTable";
import FundCategoryTable from "../components/FundCategoryTable";
import { Fade } from "react-awesome-reveal";
import DataroomViewer from "../components/DataroomViewer";
import InvestingEntities from "../components/InvestingEntities";

export const AdminComponent = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "Info"
  );

  const loadData = async () => {};

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/admin",
    });

    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    return () => {
      // Side-effect cleanup
    };
  }, [activeTab]);

  return (
    <div className="mb-5 mx-5" fluid>
      <Breadcrumb>
        <BreadcrumbItem active>Admin</BreadcrumbItem>
      </Breadcrumb>

      <Nav>
        <NavItem>
          <NavLink
            className={activeTab === "Info" ? "active" : ""}
            onClick={() => {
              setActiveTab("Info");
            }}
          >
            System Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "Configuration" ? "active" : ""}
            onClick={() => {
              setActiveTab("Configuration");
            }}
          >
            Configuration
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === "Dataroom" ? "active" : ""}
            onClick={() => {
              setActiveTab("Dataroom");
            }}
          >
            Data Room
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "VCFund" ? "active" : ""}
            onClick={() => {
              setActiveTab("VCFund");
            }}
          >
            Funds
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "FundCategory" ? "active" : ""}
            onClick={() => {
              setActiveTab("FundCategory");
            }}
          >
            Fund Category
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "LP" ? "active" : ""}
            onClick={() => {
              setActiveTab("LP");
            }}
          >
            Manage LPs
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={activeTab === "InvestingEntity" ? "active" : ""}
            onClick={() => {
              setActiveTab("InvestingEntity");
            }}
          >
            Manage Investing Entities
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={activeTab === "User" ? "active" : ""}
            onClick={() => {
              setActiveTab("User");
            }}
          >
            Manage Users
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="Info">
          <Fade>
            <Container className="mt-3" fluid>
              <AdminInfo />
            </Container>
          </Fade>
        </TabPane>

        <TabPane tabId="Dataroom">
          <Fade>
            <Container className="mt-3">
              <DataroomViewer />
            </Container>
          </Fade>
        </TabPane>

        <TabPane tabId="Configuration">
          <Fade>
            <Container className="mt-3" fluid>
              <ConfigurationTable editEnabled={true} />
            </Container>
          </Fade>
        </TabPane>

        <TabPane tabId="VCFund">
          <Fade>
            <Container className="mt-3" fluid>
              <VCFundTable editEnabled={true} publicView={false} title={""} />
            </Container>
          </Fade>
        </TabPane>

        <TabPane tabId="FundCategory">
          <Fade>
            <Container className="mt-3" fluid>
              <FundCategoryTable />
            </Container>
          </Fade>
        </TabPane>

        <TabPane tabId="LP">
          <Fade>
            <Container className="mt-3" fluid>
              <LPTable editEnabled={true} />
            </Container>
          </Fade>
        </TabPane>
        <TabPane tabId="InvestingEntity">
          <Fade>
            <Container className="mt-3" fluid>
              <InvestingEntities editEnabled={true} />
            </Container>
          </Fade>
        </TabPane>
        <TabPane tabId="User">
          <Fade>
            <Container className="mt-3" fluid>
              <UserTable editEnabled={true} />
            </Container>
          </Fade>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withAuthenticationRequired(AdminComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
