import BaseService from "./BaseService";

class InternalNoteService {

  static async getAll(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/investingentity`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async addInternalNote(token, data) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/internalnote/action/add`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

  static async updateInternalNote(token, data, id) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/internalnote/update/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

  static async removeInvestingEntity(token, data, id) {
    let d = JSON.stringify(data)
    let baseURL = BaseService.baseURL()
    const response = await fetch(`${baseURL}api/v1/investingentity/remove/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: d,
      credentials: 'include'
    });
    return response.statusText
  }

}


export default InternalNoteService;
