import React, {useState, useEffect } from "react";
import "./Onboarding.scss";
import { Link } from "react-router-dom";

export const OnboardingEmpty = (props) => {
  const { takeAction} = { ...props };
  

  const loadData = async () => {
    try {
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  return (
    <div>
      <div className="step-title pt-5">No Entity Registered</div>
      <div className="onboarding-body py-3">
        <div className="py-1 d-flex align-items-center">
          <div md={1} className="px-2">
            <div class="action-icon">+</div>
          </div>
          <div>
            <div className="step-title">
              <div className="step-body">
                
              </div>
              <Link 
                className="step-title action-message padding-0"
                onClick={() => takeAction("add")}
                >
                  Click to add entity
              </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingEmpty;
