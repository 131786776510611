import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => (
  <div className="mh-100 spinner">
    <Spinner className="m-5 justify-content-center" color="info">
      Loading...
    </Spinner>
  </div>
);

export default Loading;
