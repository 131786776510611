import React, {useState, useEffect } from "react";
import "./Onboarding.scss";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {  
  DOCUMENT_STATUS 
} from "../constants";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';


import { BsArrowRepeat, BsDownload, BsEye, BsUpload } from 'react-icons/bs';

import { Link } from "react-router-dom";

import OnboardingService from "../service/OnboardingService";
import { Button, Fade, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormGroup } from "react-bootstrap";
import InvestingEntityService from "../service/InvestingEntityService";

export const OnboardingSubDoc = (props) => {
  const { investingEntity, goToStep, selectedItemId } = { ...props };
  const { getAccessTokenSilently } = useAuth0();
  const [subDocURL, setSubDocURL] = useState();
  const [subDocStatus, setSubDocStatus] = useState(investingEntity?.subDocStatus);
  const [completedSubDocURL, setCompletedSubDocURL] = useState();
  const [uploadError, setUploadError] = useState();
  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(investingEntity?.subDocGUID ? true : false);
  const [modal, setModal] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  
  const statusStepMap = {
    [DOCUMENT_STATUS.TO_BE_STARTED]: 0,
    [DOCUMENT_STATUS.STARTED]: 1,
    [DOCUMENT_STATUS.PENDING_TO_REVIEW]: 2,
    [DOCUMENT_STATUS.REVIEW_IN_PROGRESS]: 2,
    [DOCUMENT_STATUS.REQUESTED_UPDATE]: 1,
    [DOCUMENT_STATUS.COMPLETED]: 3,
  };
  const [activeStep, setActiveStep] = useState(statusStepMap[investingEntity?.subDocStatus]);

  const loadData = async () => {
    try {
      setActiveStep(statusStepMap[subDocStatus]);
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    if (file) {
      setIsUploaded(true);
      handleNext("uploaded");
    }

    return () => {
      // Side-effect cleanup
    };
  }, [file]);

  const toggleModal = () => {
    setModal(!modal);
  }
  const downloadTemplate = async () => {
    if(!subDocURL){
      const token = await getAccessTokenSilently();
      let resp = await OnboardingService.getSubDocTemplateURL(token);
      if(resp && resp.PreAuthURL) {
        setSubDocURL(resp.PreAuthURL);
        window.open(resp.PreAuthURL, '_blank');
      } else {
        window.open(subDocURL, '_blank');
      }
    }
    
  }

  const downloadCompletedTemplate = async () => {
    if(!completedSubDocURL){
      const token = await getAccessTokenSilently();
      let resp = await OnboardingService.getPreAuthURL(token, investingEntity?.subDocGUID);
      if(resp && resp.PreAuthURL) {
        setCompletedSubDocURL(resp.PreAuthURL);
        window.open(resp.PreAuthURL, '_blank');
      } else {
        window.open(completedSubDocURL, '_blank');
      }
    }
    
  }
  const steps = [{
      label: <span>Download V0 SPV Subscription Document and Investment Questionnaire Template for {investingEntity?.name}</span>,
      content: 
      <Link onClick={() => {handleNext("downloaded"); downloadTemplate();}} className="step-action-message">
                <div>
                <BsDownload className="mr-2"/>
                <span>Download</span>
                </div>
      </Link>,
    },
    {
      label: (subDocStatus === DOCUMENT_STATUS.REQUESTED_UPDATE) ?
        'V0 team has requested an update. Please upload the updated template' :
        'Complete and upload the template',
      content: 
        <Form id="uploadForm" enctype="multipart/form-data" onSubmit={async (e) => {
        e.preventDefault();
        
        const fileInput = document.getElementById('pdfFile');
        setUploadedFileName(fileInput.files[0]?.name);
        try {
          const token = await getAccessTokenSilently();
          let resp;
          if(investingEntity.subDocGUID){
            resp = await OnboardingService.replaceSubDoc(token, fileInput.files[0], investingEntity?.subDocGUID);
          } else {
            resp = await OnboardingService.uploadSubDoc(token, fileInput.files[0], investingEntity?.id);
          }
          if(resp.status(200)) {
            const data = {
              subDocStatus: DOCUMENT_STATUS.PENDING_TO_REVIEW,
            };
            await InvestingEntityService.updateInvestingEntity(token, data, investingEntity.id);
            setSubDocStatus(DOCUMENT_STATUS.PENDING_TO_REVIEW);
          }
        } catch (e) {}
      }}>
          <FormGroup>
            <Input
              id="pdfFile"
              type="file"
              name="pdfFile"
              accept=".pdf"
              disabled={subDocStatus === DOCUMENT_STATUS.TO_BE_STARTED}
              style={{
                display: 'none',
                border: 'none !important',
              }}
              onChange={(e) => {
                if (e.target.files[0]) {
                  if (e.target.files[0].type === 'application/pdf') {
                    setUploadError(null);
                    
                  } else {
                    setUploadError('Only pdf files are accepted');
                  }
                }
                if (e.target.files[0]?.name && !uploadError) {
                  document.getElementById('uploadForm').dispatchEvent(
                    new Event('submit', {
                      bubbles: true,
                    })
                  );
                  setFile(e.target.files[0]);
                  toggleModal();
                }
              }}
            />
            
              {!isUploaded && (
                <label className="custom-step-label step-action-message" htmlFor="pdfFile" hidden={subDocStatus === DOCUMENT_STATUS.TO_BE_STARTED} style={{border: 'none !important'}}>
                  <BsUpload className="mr-2"/>
                  <span>Upload completed template</span>
                </label>
              )}
              <div className="d-flex">
              {isUploaded && (
                //todo: add last update timestamp
                <div>
                  <Link onClick={() => {downloadCompletedTemplate();}} className="custom-step-label step-action-message">
                  <div>
                  <BsEye className="mr-2"/>
                  <span>View uploaded template</span>
                  </div>
                  </Link>
                </div>
              ) 
              }
              {isUploaded && (subDocStatus === DOCUMENT_STATUS.PENDING_TO_REVIEW || subDocStatus === DOCUMENT_STATUS.REQUESTED_UPDATE) && (
                <label className="custom-step-label step-action-message ml-3" htmlFor="pdfFile" style={{border: 'none !important'}}>
                <BsArrowRepeat className="mr-2"/>
                <span>Update template</span>
              </label>
              )
              }
              </div>
            {uploadError && (
              <div style={{color: 'red'}}>
                {uploadError}
              </div>
            )}
          </FormGroup>
      </Form>
    },
    {
      label: 'V0 Review',
      content:  
      <div className='step-message'>
        {(subDocStatus !== DOCUMENT_STATUS.TO_BE_STARTED && subDocStatus !== DOCUMENT_STATUS.STARTED) && `${subDocStatus}`}
      </div>,
    },
    {
      label: 'Ready to Invest',
      content:  
      <div className='step-message'>
        <span>Subscription approved. </span>
        <Link className="step-action-message"
          disabled={subDocStatus !== DOCUMENT_STATUS.COMPLETED}
          onClick={() => {
            if(subDocStatus === DOCUMENT_STATUS.COMPLETED) {
            goToStep('kyc', selectedItemId, investingEntity)
          }}}
        >Start the KYC/AML process > </Link>
      </div>,
      description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
  ];

  const QontoConnector = styled(StepConnector)(({}) => ({
    
    marginLeft: '4px !important',

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#333333',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#333333',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#cccccc',
    },
  }));
  
  const QontoStepIconRoot = styled('div')(({}) => ({

    '& .QontoStepIcon-circle-completed': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#333333',
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#3e7cb1',
    },
    '& .QontoStepIcon-circle-inactive': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#cccccc',
    },
  }));
  
  function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
      
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="QontoStepIcon-circle-completed" />
        ) : (active ? (
          <div className="QontoStepIcon-circle" />
        ) : <div className="QontoStepIcon-circle-inactive" />)}
      </QontoStepIconRoot>
    );
  }
  
  QontoStepIcon.propTypes = {
  /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };
  const handleNext = (action) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    takeAction(action)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const takeAction = async(action) =>{
    const token = await getAccessTokenSilently();
    let data = {};
    switch (action) {
      case 'downloaded':
        if(activeStep === 0) {
          data = {
            subDocStatus: DOCUMENT_STATUS.STARTED,
          };
          await InvestingEntityService.updateInvestingEntity(token, data, investingEntity.id);
          setSubDocStatus(DOCUMENT_STATUS.STARTED);
        }
        break;
      case 'uploaded':
        if(activeStep === 1) {
          data = {
            subDocStatus: DOCUMENT_STATUS.PENDING_TO_REVIEW,
          };
          await InvestingEntityService.updateInvestingEntity(token, data, investingEntity.id);
          setSubDocStatus(DOCUMENT_STATUS.PENDING_TO_REVIEW);
          
        }
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Modal 
        isOpen={modal} 
        toggle={toggleModal}
        backdrop={"static"}
        centered={true}
        fullscreen={"sm"}
        fade={true}
        size={"md"}
        scrollable={true}
        >
        <ModalBody className={"bg-light text-center h-50 py-5"}>
        <div className="py-1">
          <h4>Upload Successfully</h4>
            <div className={"text-muted text-light py-4"}>
                Your file "{uploadedFileName}" has been successfully uploaded.
            </div>
        </div>
        <Fade cascade className="pt-3">
                <Button
                  className="mx-0 my-1 base-button primary-button"
                  onClick={toggleModal}
                >
                  Close
                </Button>
        </Fade>
        </ModalBody>
      </Modal>
    {(
      <div>
        
        <div className="onboarding-title pt-3">Subscription Document and Investment Questionnaire Template</div>
        <div className="pt-3">
        <Stepper 
          activeStep={activeStep} 
          orientation="vertical" 
          connector={<QontoConnector/>}
          >

          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                className = "pt-0 pb-0 custom-step-label"
                StepIconComponent={QontoStepIcon}
              >
                {step.label}
                <Typography>{step.content}</Typography>
              </StepLabel>
              <StepContent
                sx={{ display: 'block' }}
                // className = "ml-1"
                className="step-message"

              >
              </StepContent>
            </Step>
          ))}
        </Stepper>
        </div>
      </div>
    )}
    </div>
  );
};

export default OnboardingSubDoc;
