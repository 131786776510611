import BaseService from "./BaseService";

class OnboardingService {

  static async getSubDocTemplateURL(token) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/onboarding/template/subdoc`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async getPreAuthURL(token, guid) {
    let baseURL = BaseService.baseURL();
    const response = await fetch(`${baseURL}api/v1/onboarding/preauth/${guid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async uploadSubDoc(token, file, investingEntityId) {
    let baseURL = BaseService.baseURL();
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(`${baseURL}api/v1/onboarding/upload/subDoc/${investingEntityId}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

  static async replaceSubDoc(token, file, investingEntityId) {
    let baseURL = BaseService.baseURL();
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(`${baseURL}api/v1/onboarding/replace/${investingEntityId}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      credentials: "include",
    });
    const responseData = await response.json();
    return responseData;
  }

}

export default OnboardingService;
