import React, { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import BaseService from "../service/BaseService";
import { checkIsAuthorized } from "../utils/authorization";
import { ACCESS } from "../constants";

import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import "./ManageTeam.scss";
import TeamMembers from "../components/TeamMembers";
import Onboarding from "../components/Onboarding";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

export const ManageTeamComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [context] = useContext(UserContext);
  const [user, setUser] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lpProfiles, setLPProfiles] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [legalName, setLegalName] = useState("");
  const [activeTab, setActiveTab] = useState("Members");

  const loadData = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    if (!checkIsAuthorized(context, [ACCESS.MANAGE_MEMBERSHIP])) {
      navigate("/");
      return;
    }

    let resp = await BaseService.getUser(token);
    if (resp) {
      setUser(resp.data);
    }
    let resp2 = (await BaseService.getLPProfile(token)) || {};
    setLPProfiles(resp2);

    let lp = resp2.find((lp) => lp.code === resp.data.meta.defaultLPEntityCode);
    if (lp) {
      setLegalName(lp.meta.legalName);
    }
    let resp3 = (await BaseService.getLPTeamMembers(token, lp.code)) || {};

    setData(resp3);

    setLoading(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    loadData();
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    //localStorage.setItem("activeManageTeamTab", activeTab);
    return () => {
      // Side-effect cleanup
    };
  }, [activeTab]);

  return (
    <div>
      {loading && <Loading />}
      {/* {!loading && investingEntities?.length === 0 && (
     <div>
       <OnboardingEmpty/>
     </div>
     )} */}
      {!loading && (
        <Container className={"mb-5 px-lg-5 px-xl-5 px-3 ManageTeam"}>
          <h3 className="welcome-title mt-5 mb-5 px-3  pb-3">{companyName}</h3>

          <div className="mt-3">
            <Nav>
              {/* <NavItem>
                <NavLink
                  className={activeTab === "Onboarding" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("Onboarding");
                  }}
                >
                  Onboarding
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  className={activeTab === "Members" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("Members");
                  }}
                >
                  Members
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="Onboarding">
                <Fade>
                  <Container className="mt-5 px-0">
                    <Onboarding
                      lpEntityCode={user?.meta?.defaultLPEntityCode}
                      lpLegalName={legalName}
                    />
                  </Container>
                </Fade>
              </TabPane>
              <TabPane tabId="Members">
                <div>
                  <h4 className="title mt-5 mb-2 px-3  pb-3">
                    Members
                    <Link class="px-4 action-message" onClick={openModal}>
                      Add New Member Request{" "}
                    </Link>
                  </h4>
                </div>

                <Fade>
                  <div>
                    {isModalOpen && (
                      <TeamMembers
                        lpEntityCode={user?.meta?.defaultLPEntityCode}
                        lpCompanyName={companyName}
                        data={data}
                        newRequest={true}
                        action="add"
                        modal={isModalOpen}
                        onClose={closeModal}
                      />
                    )}
                  </div>
                  <Container className="mt-0 px-0">
                    <TeamMembers
                      lpEntityCode={user?.meta?.defaultLPEntityCode}
                      lpCompanyName={companyName}
                      data={data}
                      onClose={closeModal}
                    />
                  </Container>
                </Fade>
              </TabPane>

              <TabPane tabId="Billing">
                <Fade>
                  <Container className="mt-5"></Container>
                </Fade>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      )}
    </div>
  );
};

export default withAuthenticationRequired(ManageTeamComponent, {
  onRedirecting: () => <Loading />,
  returnTo: () => {
    localStorage.setItem(
      "returnTo",
      `${window.location.pathname}${window.location.hash}${window.location.search}`
    );

    return `${window.location.pathname}${window.location.hash}`;
  },
});
