import React, {useState, useEffect } from "react";
import "./Onboarding.scss";
import { 
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";



export const OnboardingSideMenu = (props) => {
  const { investingEntities, goToStep, selectedItemId } = { ...props };
  const [open, setOpen] = useState(selectedItemId);
  const [selectedInvestingEntity, setSelectedInvestingEntity] = useState(investingEntities[0]);
  const [activeLink, setActiveLink] = useState(null);
  const toggle = (id, investingEntity) => {
    if (open === id) {
      setOpen();
      setSelectedInvestingEntity(investingEntity);
      goToStep('intro', id, investingEntity);
      
    } else {
      setOpen(id);
      setSelectedInvestingEntity(investingEntity);
      goToStep('intro', id, investingEntity);
    }
  };

  const loadData = async () => {
    try {
      // if (!token) {
      //   return;
      // }
    } catch (e) {}
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  // if (investingEntities.length === 1) {
  //   // If there's only one item, render content directly
  //   return (
  //     <div>
  //       <h3>{investingEntities[0].title}</h3>
  //       <p>{investingEntities[0].content}</p>
  //     </div>
  //   );
  // }

  return (
    <div>
    {/* {!investingEntities && <Loading />} */}
    {/* {investingEntities && ( */}
   
   <div>
    <div>
      <Accordion open={open} toggle={() => toggle(open, selectedInvestingEntity)}>
      {investingEntities.map((investingEntity, index) => (
        <AccordionItem key={index}>
          <AccordionHeader 
            targetId={index} 
            onClick={() => toggle(index, investingEntity)}
            className={open === index ? 'active-accordion-item' : ''}
          >{investingEntity?.name}
          </AccordionHeader>
          <AccordionBody accordionId={index}>
            <Row>
              <Link 
                id="subdoc"
                // to="/onboarding/subdoc"
                onClick={() => {
                  setActiveLink('subdoc');
                  goToStep('subdoc', index, investingEntity)}
                }
                className={activeLink === 'subdoc' ? 'active-link py-2 menu-step' : 'py-2 menu-step'}
              >Subscription Document
              </Link>
            </Row>
            <Row>
              <Link 
                id="intro"
                onClick={() => {
                  setActiveLink('kyc');
                  goToStep('kyc', index, investingEntity)
                }}
                className={activeLink === 'kyc' ? 'active-link py-2 menu-step' : 'py-2 menu-step'}
                >KYC Process
              </Link>
            </Row>
            <Row>
              <Link className="py-2 menu-step">Tax Form</Link>
            </Row>
          </AccordionBody>
        </AccordionItem>
      ))}
      </Accordion>
    </div>
    </div>
    {/* )} */}
    </div>
  );
};

export default OnboardingSideMenu;
